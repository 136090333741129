import { get } from "lodash";
import { Col, Row, Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import InputSelect from "../components/Inputs/InputSelect";
import InputText from "../components/Inputs/InputText";

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function RowInput(props) {
  const formik = useFormikContext();

  /**
   * 
   * @param {String} type 
   * @returns {React.FC}
   */
  const renderInput = (type) => {
    switch(type) {
      case 'select':
        return <InputSelect name={props.name}>{props.children}</InputSelect>

      default:
        return <InputText {...props}/>;
    };
  }

  return (
    <Form.Group as={Row} controlId={props.name} style={props.style}>

      <Form.Label column sm="3" className="text-right">
        {props.label}
      </Form.Label>

      <Col sm="9">

        {props.type ? renderInput(props.type) : props.children}

        {get(formik.errors, props.name) && get(formik.touched, props.name) ? (
          <div className="text-danger">{get(formik.errors, props.name)}</div>
        ) : null}

      </Col>

    </Form.Group>
  );
}
