import React from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import FullPageError from 'components/FullPageError';

import ConstructionSiteIndexView from './constructionSite';
import ConstructionSiteEditView from './constructionSite/edit';
import ConstructionSiteShowView from './constructionSite/show';

import ConstructionSitesMapView from './tools/ConstructionSitesMap';

export default function PlanningRoutesGroup() {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>

        <Route path={`${path}/construction_sites`}component={ConstructionSiteIndexView} exact/>
        <Route path={`${path}/construction_sites/new`}component={ConstructionSiteEditView}/>
        <Route path={`${path}/construction_sites/:id/edit`}component={ConstructionSiteEditView}/>
        <Route path={`${path}/construction_sites/:id`}component={ConstructionSiteShowView}/>

        <Route path={`${path}/tools/construction_sites_map`}component={ConstructionSitesMapView}/>
        
        
        <Route path={`${path}`}>
          <FullPageError type="error" title="Questa pagina non esiste!"/>
        </Route>
      </Switch>
    </React.Fragment>
  );
}
