import React from "react";
import { Link } from "react-router-dom";

/**
 * 
 */
function ResourceLink(props) {
  const resource = props.resource;

  if(!props.resource)
    return React.Fragment;

  return <Link to={`${props.to}/${resource._id}`}>{resource.name}</Link>
}

function LinkUser(props) { return <ResourceLink to='/administration/users' resource={props.resource } /> };
function LinkTeam(props) { return <ResourceLink to='/administration/teams' resource={props.resource } /> };
function LinkConstructionSite(props) { return <ResourceLink to='/planning/construction_sites' resource={props.resource } /> };

export {
  LinkUser,
  LinkTeam,
  LinkConstructionSite
}