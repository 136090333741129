import * as Yup from 'yup';
import { formSchema as sectionCostsMaterialFormSchema } from "../planning/SectionCostsMaterial";
import { formSchema as sectionCostsWorkersFormSchema } from "../planning/SectionCostsWorkers";

// Default form values
const formStructure = {
  executed_at: "",
  section: "",
  foreman: "",
  hours: 8,
  dig_length: 0,
  completed_length: 0,
  rain: false,
  weather: "clear",
  surface: "",
  restoration: false,
  annotations: "",
  section_workers: [],
  section_materials: []
}

// Form validation schema
const formSchema = Yup.object().shape({
  executed_at: Yup.date()
    .required('Devi inserire la data del cantiere'),
  section: Yup.string()
    .required('Devi selezionare una tratta'),
  foreman: Yup.string()
    .required('Devi specificare il capo cantiere'),
  hours: Yup.number()
    .min(0, "Non puoi lavorare meno di 0 ore")
    .max(12, "Non puoi lavorare più di 12 ore in un giorno")
    .required('Devi inserire le ore lavorate nella giornata'),
  dig_length: Yup.number()
    .min(0, "Non puoi scavare un numero negativo di metri")
    .required('Devi inserire la lunghezza in metri del taglio'),
  completed_length: Yup.number()
    .min(0, "Non puoi chiudere un numero negativo di metri")
    .required('Devi inserire la lunghezza in metri di scavo chiuso'),
  rain: Yup.boolean()
    .required('Hai interrotto i lavori per pioggia?'),
  weather: Yup.string()
    .required('Specifica le condizioni meteo'),
  surface: Yup.string()
    .required('Specifica la superficie di lavoro'),
  section_workers: Yup.array().of(sectionCostsWorkersFormSchema),
  section_materials: Yup.array().of(sectionCostsMaterialFormSchema)
});

export { formStructure, formSchema };
