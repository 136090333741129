import { Badge } from "react-bootstrap";

/**
 * Displays a badge red or green depending on the condition
 */
export default function BadgeBoolean(props) {

  if(!props.condition)
    return <Badge variant="danger">{props.falseLabel || "NO"}</Badge>

  return <Badge variant="success">{props.trueLabel || "SI"}</Badge>;
}
