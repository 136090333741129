import React, { useContext } from "react";
import { CapabilitiesContext } from "../contextes/CapabilitiesContext";

/**
 * Displays a badge red or green depending on the condition
 */
export default function CapabilityFence(props) {
  const  { can } = useContext(CapabilitiesContext);

  if(!can.includes(props.can))
    return <React.Fragment />;
  
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}
