const googleMapsStyleNoPoi = [
  {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  }
];

export default googleMapsStyleNoPoi;