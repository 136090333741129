import Table from "rc-table";
import { Link } from "react-router-dom";
import BlockEmpty from "components/BlockEmpty";
import AuthContext from "contextes/AuthContext";
import { intersection } from "lodash-es";
import { useContext } from "react";

export default function  DataCostsTablesWorkersTable(props) {
  const  { user } = useContext(AuthContext);

  const columns = [
    {
      title: "Nome",
      dataIndex:"worker",
      render: v => <Link to={"/administration/workers/" + v._id}>{v.name}</Link>
    }
  ]

  if(intersection(["economics", "administration"], user.permissions).length !== 0)
    columns.push({
      title: "Costo Orario",
      dataIndex:"cost",
      render: v => `${v} CHF/Ora`,
      width: "25%"
    });


  return (
    <Table
      columns={columns}
      data={props.data}
      emptyText={<BlockEmpty message="Nessun Lavoratore" />}
      rowKey={record => record._id}
    />
  );
}
