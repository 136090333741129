import { createContext } from "react";

/**
 * Holds the information about the currently logged in user
 */
const PlanningIndexContext = createContext({
  
});


export default PlanningIndexContext;
