import { Form } from "react-bootstrap";
import { useField } from "formik";


/**
 * 
 * 
 * @param {*} props 
 * @returns 
 */
export default function InputSwitch(props) {
  const [field] = useField(props);

  return (
    <Form.Check 
      {...field}
      {...props}
      type="switch"
      id={"switch-" + props.name}
      className={"mt-2 " + props.className}
      checked={field.value}
    />
  );
}
