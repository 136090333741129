import BadgeSectionWorkState from "components/BadgeSectionWorkState";
import { Link } from "react-router-dom";
import TableGeneric from "components/data/TableGeneric";
import ConstructionSitesTableFiltersForm from "./ConstructionSitesTableFiltersForm";
import { BiEuro } from "react-icons/bi";

const columns = [
  {
    title: 'Codice',
    dataIndex: 'code',
    disableSort: true,
    width: '10%',
    render: (v, r) => <Link to={`/planning/construction_sites/${r._id}`}><code>{v}</code></Link>
  },
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Comune',
    dataIndex: 'city',
    width: '15%'
  },
  {
    title: 'Via',
    dataIndex: 'street',
    width: '22%'
  },
  {
    title: <BiEuro />,
    dataIndex: 'budget',
    width: '1%',
    disableSort: true,
    className: "text-center",
    render: v => v && <BiEuro />
  },
  {
    title: 'Stato',
    dataIndex: 'work_state',
    disableSort: true,
    render: v => <BadgeSectionWorkState value={v} />
  }
]

export default function  ConstructionSitesTable(props) {
  
  return (
    <TableGeneric 
      columns={columns}
      resource="planning/sections"
      rowLinkLocation="planning/construction_sites"
      defaultSort={["started_at", "asc"]}
      filtersForm={ConstructionSitesTableFiltersForm}
      filtersDefaults={{state: "open"}}
      //allowXlsxDownload={true}
    />
  );
}
