import { Link } from "react-router-dom";
import { BiCloud, BiCloudRain, BiSun } from "react-icons/bi";
import TableGeneric from "../../TableGeneric";
import FormattedDate from "components/FormattedDate";
import WorkReportsTableFiltersForm from "./WorkReportsTableFiltersForm";

const columns = [
  {
    title: "Nome",
    dataIndex:"name",
    disableSort: true,
    render: (v, r) => <Link to={"/reporting/work_reports/" + r._id}>{v}</Link>
  },
  {
    title: "Tratta",
    dataIndex:"section",
    disableSort: true,
    render: v => v ? <Link to={"/planning/sections/" + v._id}>{v.code}</Link> : <span className="text-danger">Tratta eliminata</span>
  },
  {
    title: "Comune",
    dataIndex:"section",
    disableSort: true,
    render: v => v && v.city
  },
  {
    title: "Tratta",
    dataIndex:"section",
    disableSort: true,
    render: v => v && v.street
  },
  {
    title: "Capo Cantiere",
    dataIndex:"foreman",
    disableSort: true,
    render: v => <Link to={"/administration/workers/" + v._id}>{v.name}</Link>
  },
  {
    title: "Metri Chiusi",
    className: "text-center",
    dataIndex:"completed_length"
  },
  {
    title: <BiCloud />,
    dataIndex:"rain",
    className: "text-center",
    disableSort: true,
    render: v => v ? <BiCloudRain color="#3BABFD"/> : <BiSun color="#FC9601"/>
  },
  {
    title: "Data",
    dataIndex: "executed_at",
    render: (v, r) => <FormattedDate date={v} />
  }
]

export default function  DataWorkReportsTable(props) {

  return (
    <TableGeneric
      columns={columns}
      resource="reporting/work_reports"
      rowLinkLocation="reporting/work_reports"
      defaultSort={["executed_at", "desc"]}
      filtersForm={WorkReportsTableFiltersForm}
      allowXlsxDownload={true}
    />
  );
}
