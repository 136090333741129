import React from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import FullPageError from 'components/FullPageError';

import WorkReportsEditView from './work_reports/edit';
import WorkReportsShowView from './work_reports/show';
import WorkReportsIndexView from './work_reports';


export default function ReportingRoutesGroup() {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>

        <Route path={`${path}/work_reports`}component={WorkReportsIndexView} exact/>
        <Route path={`${path}/work_reports/new`}component={WorkReportsEditView}/>
        <Route path={`${path}/work_reports/:id/edit`}component={WorkReportsEditView}/>
        <Route path={`${path}/work_reports/:id`}component={WorkReportsShowView}/>
        
        <Route path={`${path}`}>
          <FullPageError type="error" title="Questa pagina non esiste!"/>
        </Route>
      </Switch>
    </React.Fragment>
  );
}
