export default function Identicon(props) {

  const size = props.size || 64;
  const src = `https://www.gravatar.com/avatar/${props.md5}?size=${size}&default=identicon`;

  return (
    <img
      src={src}
      alt={props.alt || ""}
      className={"img-fluid " + props.className}
      style={props.style}
    />
  );
}
