/**
 * 
 * @param {String} endpoint The backend endpoint
 * @param {String|null} id If this is an update the id of the resource
 * @param {String|null} redirect The path where to redirect if the update was successful
 * @returns 
 */
export default function useApiDeletor(resource) {
  
  /**
   * Send a delete request to remove the object identified by the given id
   * 
   * @param {String} values The values to send to the backend
   * @returns 
   */
  const mutate = async (id) => {

    resource += `/${id}`;

    //const response = 
    await fetch(process.env.REACT_APP_BACKEND_URL + resource, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      credentials: "include"
    });

    //const data = await response.json();

    return true;
  }

  return mutate;
}
