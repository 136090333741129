import InputWorkerSelect from "components/Inputs/InputWorkerSelect";
import RowInput from "containers/RowInput";
import { Formik } from "formik";
import { identity } from "lodash";
import { pickBy } from "lodash";
import { Button, Form } from "react-bootstrap";

export default function  WorkReportsTableFiltersForm(props) {

  /**
   * 
  */
  const handleFiltersChange = (filters) => {
    const validFilters = pickBy(filters, identity);

    props.onFilterChange(validFilters);
  }

  return (
    <Formik
      onSubmit={handleFiltersChange}
      initialValues={{foreman:"", ...props.activeFilters}}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>

          <RowInput label="Operaio">
            <InputWorkerSelect name="foreman" size="sm" />
          </RowInput>

          <div className="text-right pt-2">
            <Button variant="primary" size="sm" type="submit">Filtra</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
