import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { BiPlus } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import PageHeader from 'containers/PageHeader';
import PlanningIndexContext from 'contextes/PlanningIndexContext';
import DataWorkReportsTable from 'components/data/reporting/work_reports/WorkReportsTable';
import DataWorkReportsPanel from 'components/data/reporting/work_reports/WorkReportsPanel';

export default function WorkReportsIndexView() {
  const [activeSection, setActiveSection] = useState(false);

  const contextValue = {
    document: { active: activeSection, set: setActiveSection }
  };

  return (
    <PlanningIndexContext.Provider value={contextValue}>

      <PageHeader title="Rapporti Cantiere">
        <Button
          as={Link}
          to="/reporting/work_reports/new"
          variant="success"
        >
          <BiPlus/> Crea
        </Button>
      </PageHeader>

      <div className="content-container">
        <Row noGutters style={{height: "100%" }}>
          <Col style={{height: "100%" }}>
            <DataWorkReportsTable />
          </Col>

          <Col md={3} style={{borderLeft: "1px solid #dbdbdb"}}>
            <DataWorkReportsPanel />
          </Col>
        </Row>
      </div>


    </PlanningIndexContext.Provider>
  );
}
