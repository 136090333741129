const apiFetcher = async url => {
  const res = await fetch(process.env.REACT_APP_BACKEND_URL + url, {
    credentials: "include"
  })

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.')
    // Attach extra info to the error object.
    try {
      error.info = await res.json();
    } catch(e) {
      error.info = { error: { message: "Invalid json response" } }
    }

    error.status = res.status
    throw error
  }

  return res.json()
}

export default apiFetcher;
