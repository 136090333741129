import { ListGroup, Row, Col } from "react-bootstrap";

export default function ListGroupItemSplit(props) {
  return (
    <ListGroup.Item>
      <Row>
        <Col md="4">
          {props.label}
        </Col>
        <Col>
          {props.children}
        </Col>
      </Row>
    </ListGroup.Item>
  )
}
