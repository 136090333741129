import { Badge } from "react-bootstrap"

const badges = {
  generic: { variant: "secondary", icon: "👷", text: "Generico" },
  foreman: { variant: "dark", icon: "🔰", text: "Capo Cantiere" },
  mechanic: { variant: "info", icon: "🧑‍🔧", text: "Meccanico" },
  external: { variant: "warning", icon: "👽", text: "Esterno" }
}

export default function WorkerRoleBadge(props) {
  const badge = badges[props.role]
  return (
    <Badge variant={badge.variant}>
       {badge.icon} {badge.text}
    </Badge>
  );
}
