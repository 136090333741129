import React from "react";
import { useFormikContext } from "formik";
import { Table, Alert } from "react-bootstrap";
import TableInputText from "components/Inputs/TableInputText";

/**
 * Renders a sub form to edit works entries
 * This component can only be rendered inside the reports edit form
 * 
 * @param {*} props 
 * @returns 
 */
export default function CostsTableWorkersSubForm(props) {
  const formik = useFormikContext();

  const elements = formik.values[props.name];

  return (
    <React.Fragment>
      <Table className="inputTable mb-0">

        <thead>
          <tr>
            <th>Lavoratore</th>
            <th width="25%">Costo Orario</th>
          </tr>
        </thead>

        <tbody>

          {elements.map((w, i) => (

            <tr key={i}>
              <td>
                <span className="pl-2" style={{lineHeight: "50px"}}>{elements[i].worker.name}</span>
              </td>

              <td>
                <TableInputText type="number" name={props.name + '.' + i + '.cost'} min="0" />
              </td>

            </tr>

          ))}

        </tbody>

      </Table>

      {formik.errors.elements && typeof formik.errors.elements === 'string' && (
        <Alert variant="danger" className="m-3">{formik.errors.elements}</Alert>
      )}
    </React.Fragment>
  );
}
