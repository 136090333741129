import React, { useContext } from "react";
import useSWR from "swr";
import { Spinner } from "react-bootstrap";
import { BiBraille, BiSearchAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

import apiFetcher from "utils/apiFetcher";
import SimplePropertiesTable, { TableRow } from "components/SimplePropertiesTable";
import Toolbar, { ToolbarButton, ToolbarSpacer } from "components/Toolbar";
import BlockEmpty from "components/BlockEmpty";
import PlanningIndexContext from "contextes/PlanningIndexContext";
import ErrorDebugBlock from "components/ErrorDebugBlock";

export default function  DataWorkReportsPanel(props) {
  const planningCtx  = useContext(PlanningIndexContext);

  const { data, error } = useSWR(planningCtx.document.active && `/reporting/work_reports/${planningCtx.document.active}` , apiFetcher);
  

  // If no product is selected, return a placeholder
  if(!planningCtx.document.active)
    return <BlockEmpty message="Seleziona un rapporto" />

  if (error) return <ErrorDebugBlock error={error} />
  if (!data) return (
    <div className="text-center p-5">
      <div className="mb-3">Caricamento...</div>
      <Spinner animation="border" />
    </div>
  );
  
  const apiData = data.results;
  return (
    <React.Fragment>
      <Toolbar noTopBorder>
        <ToolbarButton icon={BiSearchAlt} label="Dettaglio" as={Link} to={`/reporting/work_reports/${apiData._id}`} />
      </Toolbar>

      <div className=" p-2">
        <h5 className="m-0">{apiData.name}</h5>
        <small className="m-0 text-muted">
          <BiBraille style={{marginTop: "-3px"}}/> {apiData.section.code}
        </small>
      </div>

      <SimplePropertiesTable borderTop>
        <TableRow label="Codice Tratta">{apiData.section.code}</TableRow>
        <TableRow label="Città">{apiData.section.address.city}</TableRow>
        <TableRow label="Tratta">{apiData.section.address.street}</TableRow>
        <TableRow label="Capo Cantiere">{apiData.foreman.name}</TableRow>
      </SimplePropertiesTable>

      <Toolbar>
        Posizione
        <ToolbarSpacer />
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://google.com/maps/search/${apiData.section.address.lat},${apiData.section.address.lon}`}
        >
          Google Maps
        </a>
      </Toolbar>

      <img
        className="img-fluid"
        alt=""
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${apiData.section.address.lat},${apiData.section.address.lon}&scale=2&zoom=16&size=640x400&maptype=roadmap&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`}
      />

      <Toolbar>Scavi</Toolbar>

      <SimplePropertiesTable borderBottom>
        <TableRow label="Metri Scavati">{apiData.dig_length}</TableRow>
        <TableRow label="Metri Chiusi">{apiData.completed_length}</TableRow>
      </SimplePropertiesTable>

    </React.Fragment>
  );
}