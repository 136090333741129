import { Form, InputGroup } from "react-bootstrap";
import { useField } from "formik";

/**
 * Renders a generic input on two colums with labels on the left
 * @param {*} props 
 * @returns 
 */
export default function InputText(props) {
  const [field] = useField(props);

  if(!(props.preLabel || props.postLabel))
    return <Form.Control {...field} {...props} />;

  const {preLabel, postLabel, ...inputProps} = props;

  return (
    <InputGroup className="mb-2">
      {preLabel && <InputGroup.Text>{preLabel}</InputGroup.Text>}
      <Form.Control {...field} {...inputProps} />
      {postLabel && (
      <InputGroup.Text style={{borderTopLeftRadius:0, borderBottomLeftRadius:0, borderLeft: 0}}>
        {postLabel}
      </InputGroup.Text>
      )}
    </InputGroup>
  );
}
