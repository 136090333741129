import { useField } from "formik";
import React, { useRef } from "react";
import { Overlay } from "react-bootstrap";

/**
 * 
 * 
 * @param {*} props 
 * @returns 
 */
export default function TableInputSelectCosts(props) {
  const [field, meta] = useField(props);
  const target = useRef(null);

  const tooltip = (
    <Overlay target={target.current} show={true} placement="top-start" transition={false}>
      {({ placement, arrowProps, show: _show, popper, ...props }) => (
        <div
          {...props}
          style={props.style}
          className="tableTooltip"
        >
          {meta.error}
        </div>
      )}
    </Overlay>
  );

 /* let filterFieldName = props.name.split(".");
  filterFieldName.splice(-2);
  filterFieldName = filterFieldName.join(".");

  const usedIMaterials = formik.values[filterFieldName].map(e => e.material).filter(n => n);

  const values = props.values.filter(e => !usedIMaterials.includes(e._id))
*/
  

  return (
    <React.Fragment>
      <select
        ref={target}
        {...field}
        {...props}
        className={"tableInput " + ( meta.touched && meta.error && 'border border-danger')}
      >
        <option disabled value=""></option>
        {props.values.map(v => <option key={v._id} value={v._id}>{v.name}</option> )}
      </select>

      {meta.touched && meta.error && tooltip}
    </React.Fragment>
  )
};
