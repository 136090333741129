import React from 'react';
import useSWR from 'swr'
import {  Row, Col, Card, Button, ListGroup, Alert, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import apiFetcher from 'utils/apiFetcher';
import Identicon from 'components/Identicon';
import FullPageError from 'components/FullPageError';
import DeleteObjectButton from 'components/DeleteObjectButton';
import ButtonJsonDebug from 'components/ButtonJsonDebug';
import CreatedUpdatedString from 'components/CreatedUpdatedString';
import ListGroupItemSplit from 'components/ListGroupItemSplit';
import BootstrapCard from 'components/BootstrapCard';
import PageHeader from 'containers/PageHeader';
import UserPermissionsList from 'components/UserPermissionsList';


export default function UsersShowView(props) {
  const { data, error } = useSWR(`users/${props.match.params.id}`, apiFetcher)
  
  if (error) return <FullPageError type="error" error={error} />
  if (!data) return <div>loading...</div>

  const results = data.results;

  return (
    <React.Fragment>

      <PageHeader
        title={`${results.firstname} ${results.lastname}`}
        meta={<CreatedUpdatedString created={results.created_at} updated={results.updated_at} deleted={results.deleted_at} />}
      >
        <ButtonJsonDebug json={data} />
          
        {!results.deleted_at &&
          <React.Fragment>
            <Button
              as={Link}
              to={`/administration/users/${results._id}/edit`}
              variant="warning"
              className="mr-2 text-white"
            >
              <FaPencilAlt/> Modifica
            </Button>
            
            <DeleteObjectButton
              resource="users"
              resourceId={results._id}
              afterDeleteRedirect="/administration/users"
            />
          </React.Fragment>
        }
      </PageHeader>
      
      <Container fluid className="content-container pt-3 pb-3">
        {results.deleted_at && <Alert variant="warning" className="mt-3">Questo utente è stato eliminato</Alert>}

        <Row>
          <Col>

            <BootstrapCard header="Informazioni di base" className="mb-4" noPadding>
              <ListGroup variant="flush">

                <ListGroupItemSplit label="Nome">
                  {results.firstname}
                </ListGroupItemSplit>

                <ListGroupItemSplit label="Cognome">
                  {results.lastname}
                </ListGroupItemSplit>

                <ListGroupItemSplit label="Email">
                  {results.email}
                </ListGroupItemSplit>

                <ListGroupItemSplit label="Permessi">
                  <UserPermissionsList permissions={results.permissions} />
                </ListGroupItemSplit>
                
              </ListGroup>
            </BootstrapCard>

          </Col>

          <Col md="4">

            <Card>
              <Card.Header>Identicon</Card.Header>
              <Identicon md5={results.email_hash} size="500" />
            </Card>

          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}