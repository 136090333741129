import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, ListGroup, Container } from "react-bootstrap";

import BootstrapCard from "components/BootstrapCard"; 
import ListGroupItemSplit from "components/ListGroupItemSplit";
import { CapabilitiesContext } from 'contextes/CapabilitiesContext';
import ConstructionSiteMaterialsTable from "components/data/planning/sections/ConstructionSiteMaterialsTable";
import ConstructionSitesWorkersTable from "components/data/planning/sections/ConstructionSitesWorkersTable";
import { BiHappy, BiSad } from "react-icons/bi";

function CostsTableLink(props) {
  if(!props.table)
    return <React.Fragment/>;

  return (
    <Link
      className="text-muted float-right"
      to={`/administration/costs_tables/${props.table._id}`}
    >
      {props.table.name}
    </Link>
  );
}

export default function EconomicsShowPartialView(props) {
  const apiData = props.data.results;

  const budget = apiData.costs.budget;
  const final = apiData.costs.final;

  const budget_total = Math.round((budget.materials_cost + budget.workers_cost + budget.incidences_general + budget.incidences_management) * 100) / 100;
  const final_total = Math.round((final.materials_cost + final.workers_cost + final.incidences_general + final.incidences_management) * 100) / 100;

  return (
    <CapabilitiesContext.Provider value={{ can: props.data.capabilities }}>
        
        <Container fluid className="content-container pt-3 pb-3">
          <Row>
            <Col>

              <h4 className="mb-3">Budget</h4>   
              
              <BootstrapCard noPadding header={<>Budget Personale <CostsTableLink table={budget.table}/></>}>
                <ConstructionSitesWorkersTable data={budget.workers} total={budget.workers_cost} />
              </BootstrapCard>
            
            </Col>

            <Col>
              <h4 className="mb-3">Consuntivo</h4>
              
              <BootstrapCard noPadding header={<>Consuntivo Personale <CostsTableLink table={final.table}/></>}>
                <ConstructionSitesWorkersTable data={final.workers} total={final.workers_cost} />
              </BootstrapCard>

            </Col>
          </Row>
  
          <BootstrapCard noPadding header={<> Materiali <CostsTableLink table={budget.table}/></>}>
            <ConstructionSiteMaterialsTable data={budget.materials} total={budget.materials_cost} />
          </BootstrapCard>

          {apiData.costs && (
            <Row>
              <Col>     
                
                <BootstrapCard noPadding header={<>Incidenze <CostsTableLink table={budget.table}/></>}>
                  <ListGroup variant="flush">
                    <ListGroupItemSplit label="Incidenze Generali">{budget.incidences_general} CHF</ListGroupItemSplit>
                    <ListGroupItemSplit label="Incidenze di gestione">{budget.incidences_management} CHF</ListGroupItemSplit>
                  </ListGroup>

                  <Row className="border-top m-0">
                    <Col className="text-right p-2 border-right font-weight-bold">Totale</Col>
                    <Col md={3} className="p-2">
                      {budget_total} CHF
                    </Col>
                  </Row>
                </BootstrapCard>
              
              </Col>

              <Col>
                
                <BootstrapCard noPadding header={<>Incidenze <CostsTableLink table={final.table}/></>}>
                  <ListGroup variant="flush">
                    <ListGroupItemSplit label="Incidenze Generali">{final.incidences_general} CHF</ListGroupItemSplit>
                    <ListGroupItemSplit label="Incidenze di gestione">{final.incidences_management} CHF</ListGroupItemSplit>
                  </ListGroup>

                  <Row className="border-top m-0">
                    <Col className="text-right p-2 border-right font-weight-bold">Totale</Col>
                    <Col md={3} className={`p-2 ${final_total > budget_total ? "text-danger" : "text-success"}`}>
                      {final_total} CHF
                      {final_total > budget_total ? (
                        <BiSad style={{marginTop: "-3px"}} className="text-danger ml-1" />
                      ) : (
                        <BiHappy style={{marginTop: "-3px"}} className="text-success ml-1" />)}
                    </Col>
                  </Row>
                </BootstrapCard>

              </Col>
            </Row>
          )}
        </Container>

    </CapabilitiesContext.Provider>
  );
}
