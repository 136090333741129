import React from "react";
import {Pagination } from "react-bootstrap"

/**
 * Displays a badge with the human name and color of the supplied role
 * 
 * @param props 
 * @returns 
 */
export default function PaginationGeneric(props){
  const selectedPage = props.selectedPage;
  const onPageChange = props.onPageChange;

  /**
   * 
   * @returns 
   */
  const pages = () => {
    const elements = [];

    for(let i=1; i<=props.pagination.total_pages; i++) {

      if(props.pagination.current_page === i)
        elements.push(<Pagination.Item active key={i}>{i}</Pagination.Item>);
      else {
        if( (i <= 2 || i >= props.pagination.total_pages - 2) || (Math.abs(i - props.pagination.current_page) <= 2) )
          elements.push(<Pagination.Item onClick={() => {props.onPageChange(i)}} key={i}>{i}</Pagination.Item>);

        if(i === props.pagination.current_page - 3 || i === props.pagination.current_page + 3)
          elements.push(<Pagination.Item key={"empty" + i} disabled>...</Pagination.Item>);
      }
    }

    return elements;
  }

  if(!props.pagination)
    return <React.Fragment />;


  return (
    <Pagination>

      <Pagination.First
        disabled={selectedPage === 1}
        onClick={() => {onPageChange(1)}}
      />
      <Pagination.Prev
        disabled={selectedPage === 1}
        onClick={() => {onPageChange(selectedPage-1)}}
      />

        {pages()}

      <Pagination.Next
        disabled={selectedPage === props.pagination.total_pages}
        onClick={() => {onPageChange(selectedPage+1)}}
      />
      <Pagination.Last
        disabled={selectedPage === props.pagination.total_pages}
        onClick={() => {onPageChange(props.pagination.total_pages)}}
      />

    </Pagination>

  );
}
