import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import apiFetcher from '../../utils/apiFetcher'

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function InputTeamSelect(props) {
  const [field] = useField(props);
  
  const [teams, setTeams] = useState([])

  /**
   * Load the list of all workers on mount.
   * This may be inefficient but in practice the component is never re-rendered
   */
  useEffect( () => {
    let apiUrl = "core/teams/select";

    apiFetcher(apiUrl)
      .then(res => {
        setTeams(res.results);
      });
  }, []);

  const {filterRole, ...inputProps} = props;

  return(
    <Form.Control as="select"
      {...field}
      {...inputProps}
    >
      <option value=""></option>
      {teams.map(v => <option key={v._id} value={v._id}>{v.name}</option> )}
    </Form.Control>
  );
}
