import React, { useState } from "react";
import Table from "rc-table";
import { BiDownload, BiFile } from "react-icons/bi";
import BlockEmpty from "components/BlockEmpty";
import BootstrapCard from "components/BootstrapCard";
import BadgeBoolean from "components/BadgeBoolean";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import InputSwitch from "components/Inputs/InputSwitch";
import InputUpload from "components/Inputs/InputUpload";
import useApiMutator from "utils/apiMutator";
import { mutate } from "swr";
import documentsTypologies from "utils/constants/documentsTypologies";


function DocumentEditModal(props) {
  const [mutateResource, mutationError]= useApiMutator(`planning/sections/${props.construction_site_id}/documents`);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (values) => {
    
    // eslint-disable-next-line
    const data = await mutateResource({ document: values }, { format: "formdata" });


    if(!mutationError) {
      mutate(`planning/sections/${props.construction_site_id}`);
      
      handleClose();
    }
  }

  return (
    <>
      <span
        className="text-body"
        onClick={handleShow}
        style={{ cursor: "pointer" }}
      >
        <BiFile style={{marginTop: "-3px", marginRight: "4px"}}/>{documentsTypologies[props.data.typology]}
      </span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica documento</Modal.Title>
        </Modal.Header>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{...props.data, file: null}}
        >
          {(formik) => (   
            <Form noValidate onSubmit={formik.handleSubmit}>

              <Modal.Body>
                  <InputSwitch name="presence" label="Presente?" className="mb-3"/>
        
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Carica documento (Solo PDF)</Form.Label>
                    <InputUpload name="file" />
                  </Form.Group>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit">
                  Salva
                </Button>
              </Modal.Footer>

            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default function  DocumentsCard(props) {
  
  const columns = [
    {
      title: 'Nome',
      dataIndex: '_typology',
      render: (v, r) => (
       <DocumentEditModal data={r} construction_site_id={props.data._id} />
      )
    },
    {
      title: 'Presente?',
      dataIndex: 'presence',
      width: "10%",
      className: "text-center",
      render: v => <BadgeBoolean condition={v} />
    },
    {
      title: 'Download',
      dataIndex: 'file_url',
      width: "10%",
      className: "text-center",
      render: v => v && <a href={v} target="_blank" rel="noreferrer"><BiDownload /></a>
    }
  ]

  const cardHeader = (
    <React.Fragment>
      Documenti
    </React.Fragment>
  );

  return (
    <BootstrapCard
      header={cardHeader}
      noPadding
    >
      <Table
        columns={columns}
        data={props.data.documents}
        emptyText={<BlockEmpty message="Nessun Allegato" />}
        rowKey={record => record._id}
      />
    </BootstrapCard>
  );
}