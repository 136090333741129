import useSWR from 'swr';
import { useHistory } from "react-router";

import apiFetcher from 'utils/apiFetcher';
import useApiMutator from 'utils/apiMutator';

import { formStructure, formSchema } from "forms/reporting/work_report";

import { Row, Col, Container, Alert } from "react-bootstrap";
import FormWrapper from 'components/Forms/FormWrapper';
import FullPageError from 'components/FullPageError';
import BootstrapCard from 'components/BootstrapCard';
import RowInput from 'containers/RowInput';
import { cloneDeep, identity, pickBy } from 'lodash';
import InputWorkerSelect from 'components/Inputs/InputWorkerSelect';
import InputSwitch from 'components/Inputs/InputSwitch';
import InputSection from 'components/Inputs/InputSection';
import InputWeather from 'components/Inputs/InputWeather';
import MaterialsCostsSubform from 'components/Forms/MaterialsCostsSubform';
import WorkersCostsSubform from 'components/Forms/WorkersCostsSubform';
/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function WorkReportsEditView(props) {
  const history = useHistory();
  const id = props.match.params.id;

  const { data, error } = useSWR(id ? `reporting/work_reports/${id}` : null, apiFetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
  const [mutate, mutationError]= useApiMutator("reporting/work_reports");

  /**
   * 
   * @param {*} values 
   */
  const handleSubmit = async (values) => {
    const data = await mutate({ work_report: values });

    if(data)
      history.replace(`/reporting/work_reports/${data.results._id}`);
  }

  if (error) return <FullPageError type="error" error={error} />
  if (id && !data) return <div>loading...</div>

  let initialValues = cloneDeep(formStructure);

  if(data?.results) {
    initialValues = {...initialValues, ...pickBy(data.results, identity)}

    initialValues.section = initialValues.section?._id;
    initialValues.foreman = initialValues.foreman?._id;

    initialValues.section_workers = initialValues.section_workers.map(e => {
      return { worker: e.worker._id, quantity: e.quantity }
    });

    initialValues.section_materials = initialValues.section_materials.map(e => {
      return { material: e.material._id, quantity: e.quantity }
    });

    initialValues.section_vehicles = initialValues.section_vehicles.map(e => {
      return { vehicle: e.vehicle._id, worker: e?.worker?._id  }
    });

    initialValues.section_tools = initialValues.section_tools.map(e => {
      return { tool: e.tool._id }
    });
  }

  return (
    <Container fluid className="content-container pt-3 pb-3">
      <h3 className="mb-3">
        {data ? `Modifica ${data.results.section.address.street}, ${data.results.section.address.city}` : "Aggiungi Rapporto Cantiere" }
      </h3>

      {mutationError && <Alert variant="danger">{mutationError}</Alert>}

      <FormWrapper
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
      >
        <Row>
          <Col>

            <BootstrapCard header="Informazioni Base">

              <RowInput label="Data cantiere" type="date" name="executed_at" />

              <hr />

              <RowInput label="Tratta" name="section">
                <InputSection name="section" />
              </RowInput>

              <RowInput label="Capo cantiere" name="foreman">
                <InputWorkerSelect name="foreman" filterRole="foreman"/>
              </RowInput>

              <hr />

              <RowInput label="Ore Lavorate" type="number" min="0" max="12" name="hours" />
              <RowInput label="Metri scavo eseguiti" type="number" min="0"  name="dig_length" />
              <RowInput label="Metri scavo chiusi" type="number" min="0"  name="completed_length" />

              <RowInput label="Annotazioni" type="text" as="textarea" rows={5} name="annotations" style={{marginBottom: "0rem"}}/>
              
            </BootstrapCard>          
          </Col>
          
          <Col>
            <BootstrapCard header="Varie">
              <RowInput label="Fermo per pioggia?" name="rain">
                <InputSwitch name="rain" />
              </RowInput>

              <RowInput label="Condizioni meteo" name="weather">
                <InputWeather name="weather"/>
              </RowInput>

              <RowInput label="Ripristino" name="restoration" style={{marginBottom: 0}}>
                <InputSwitch name="restoration" />
              </RowInput>
            </BootstrapCard>
          </Col>
        </Row>

        <Row>
          <Col>
            <BootstrapCard header="Operai" noPadding>
              <WorkersCostsSubform name="section_workers"/>
            </BootstrapCard>
          </Col>
          <Col>
            <BootstrapCard header="Materiali" noPadding>
              <MaterialsCostsSubform name="section_materials" />
            </BootstrapCard>
          </Col>
        </Row>

      </FormWrapper>
    </Container>
  );
}