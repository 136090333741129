import useSWR from 'swr';
import apiFetcher from 'utils/apiFetcher';
import useApiMutator from 'utils/apiMutator';
import { useHistory } from "react-router";

import { formStructure, formSchema } from "forms/administration/option";

import { Row, Col, Container, Alert } from "react-bootstrap";
import FormWrapper from 'components/Forms/FormWrapper';
import FullPageError from 'components/FullPageError';
import BootstrapCard from 'components/BootstrapCard';
import RowInput from 'containers/RowInput';
import FullPageLoading from 'components/FullPageLoading';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function OptionsEditView(props) {
  const history = useHistory();
  const id = props.match.params.id;

  const { data, error } = useSWR(id ? `core/options/${id}` : null, apiFetcher);
  const [mutate, mutationError]= useApiMutator("core/options");

  /**
   * 
   * @param {*} values 
   */
  const handleSubmit = async (values) => {
    const data = await mutate({ option: values });

    if(data)
      history.replace(`/administration/options/${data.results._id}`);
  }

  // If this is a new item, apply the default values
  let initialValues = formStructure;

  if(data?.results) {
    initialValues = data?.results;
  }

  if (error) return <FullPageError type="error" error={error} />
  if (id && !data) return <FullPageLoading />

  return (
    <Container fluid className="content-container pt-3 pb-3">

      <Row className="justify-content-md-center">
        <Col md="6">

          <h3 className="mb-3">
            {data ? `Modifica ${data.results.key}` : "Aggiungi Opzioni" }
          </h3>

          {mutationError && <Alert variant="danger">{mutationError}</Alert>}

          <FormWrapper
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >


            <BootstrapCard header="Informazioni di base">

              <RowInput label="Chiave" type="text" name="key" />
              <RowInput label="Valore" type="text" name="value" />

            </BootstrapCard>


          </FormWrapper>
        </Col>
      </Row>

    </Container>
  );
}
