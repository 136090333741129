import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { BiData } from 'react-icons/bi';
import Select, { Option } from 'rc-select';
import apiFetcher from '../../utils/apiFetcher'

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function InputCity(props) {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  const [ items, setItems ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);

  const { value } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    handleSearch(value);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Updates the value of the input field
   * 
   * @param newValue The new value to set for the input field
   */
  const handleChange = async (newValue) => {
    setValue(newValue);
  }

  /**
   * Asks the backend for the list of customers matching the query
   * 
   * @param query The string to send to the backend
   */
  const handleSearch = async (query) => {
    setIsLoading(true);
    const res = await apiFetcher(`/core/cities_select?denomination=${query}`);
    
    setItems(res.results);     
    setIsLoading(false);
  }

  const notFoundContent = (
    <div className="text-center p-3">
      <BiData size={60} color="#eee"/>
      <h6 style={{color: "#ddd"}}>Nessun elemento</h6>
    </div>
  );

  return(
    <Select
      value={value}
      filterOption={false}
      placeholder="Seleziona un comune"
      optionLabelProp="label"
      notFoundContent={notFoundContent}
      showSearch
      onSearch={handleSearch}
      onChange={handleChange}
      loading={isLoading}
      className={props.size === "sm" && "size-sm"}
      allowClear={true}
    >
      {items.length  && (
        <React.Fragment>

          {items.map((item) => (
            <Option key={item._id} value={item.name} label={item.name}>
              {item.name}
              {props.size !== "sm" && <div className="text-muted">{item.province} - {item.region}</div>}
            </Option>
          ))}

        </React.Fragment>
      )}
    </Select>

  );
}
