import { useField } from 'formik';
import { find } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import apiFetcher from 'utils/apiFetcher';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function InputSection(props) {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);
  const [city, setCity] = useState("");
  const [section, setSection] = useState("");
  const [sectionCode, setSectionCode] = useState();
  const [selectCities, setSelectCities] = useState([]);
  const [selectSections, setSelectSections] = useState([]);

  const { value } = meta;
  const { setValue } = helpers;

  /**
   * 
   */
  useEffect( () => {
    apiFetcher("planning/sections/select_active_cities")
      .then(res => {
        setSelectCities(res.results);

        if(!value)
          return;

        apiFetcher(`planning/sections/${value}`)
          .then(res => {
            setCity(res.results.address.city);
            loadSectionsByCity(res.results.address.city);
            setSection(value);
            setSectionCode(res.results.code);
          });

      });
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 
   * @param {*} event 
   */
  const handleCityChange = (event) => {
    setCity(event.target.value);
    setValue("");
    loadSectionsByCity(event.target.value);
  }

  const loadSectionsByCity = (city) => {
    setCity(city);
    setSection("");
    setSectionCode("");
    setSelectSections([]);

    apiFetcher(`planning/sections/select_active_by_city?city=${city}`)
      .then(res => {
        setSelectSections(res.results);
      });
  }

  /**
   * 
   * @param {*} event 
   */
  const handleSectionChange = (event) => {
    setSection(event.target.value);

    const section = find(selectSections, { _id: event.target.value });

    setSectionCode(section.code);

    setValue(event.target.value);
  }

  return (
    <Row>
      <Col>

        <Form.Control as="select" value={city} onChange={handleCityChange} disabled={selectCities.length === 0}>
          <option value="" disabled>Comune</option>
          {selectCities.map(e => <option key={e.city} value={e.city}>{e.city}</option>)}          
        </Form.Control>

      </Col>
      <Col>

        <Form.Control as="select" value={section} onChange={handleSectionChange}  disabled={selectSections.length === 0}>
          <option value="" disabled>Tratta</option>
          {selectSections.map(e => <option key={e._id} value={e._id}>{e.address}</option>)}  
        </Form.Control>

      </Col>
      <Col md="auto" style={{ paddingTop: "0.4em", minWidth: "110px"}} className="text-right">

        <code className="text-body font-weight-bold">{sectionCode}</code>

      </Col>
    </Row>
  );
}
