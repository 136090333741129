import InputCity from "components/Inputs/InputCity";
import InputSelect from "components/Inputs/InputSelect";
import RowInput from "containers/RowInput";
import { Formik } from "formik";
import { identity } from "lodash";
import { pickBy } from "lodash";
import { Button, Form } from "react-bootstrap";

export default function  ConstructionSitesTableFiltersForm(props) {

  /**
   * 
  */
  const handleFiltersChange = (filters) => {
    const validFilters = pickBy(filters, identity);

    props.onFilterChange(validFilters);
  }

  return (
    <Formik
      onSubmit={handleFiltersChange}
      initialValues={{visibility:"", city: undefined, ...props.activeFilters}}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>

          <RowInput label="Visibilità">
            <InputSelect name="state" size="sm">
              <option value="">Tutti i cantieri</option>
              <option value="open">Cantieri attivi</option>
              <option value="closed">Cantieri terminati</option>
            </InputSelect>
          </RowInput>

          <RowInput label="Città"><InputCity name="city" size="sm" /></RowInput>

          <div className="text-right pt-2">
            <Button variant="primary" size="sm" type="submit">Filtra</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
