import { useField } from 'formik';
import React from 'react';
import Select, { Option } from 'rc-select';
import { permissions } from 'forms/administration/user';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function InputUserPermissions(props) {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  const { value } = meta;
  const { setValue } = helpers;

  /**
   * Updates the value of the input field
   * 
   * @param newValue The new value to set for the input field
   */
    const handleChange = async (newValue, options) => {
      setValue(newValue);
    }
  
  return(
    <Select
      value={value}
      filterOption={false}
      placeholder="Nessun permesso"
      optionLabelProp="label"
      mode="multiple"
      showSearch={false}
      onChange={handleChange}
    >
      <React.Fragment>
        {Object.keys(permissions).map(m => 
          <Option key={m} value={m} label={permissions[m]}>{permissions[m]}</Option>
        )}
      </React.Fragment>
    </Select>

  );
}
