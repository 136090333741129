import * as Yup from 'yup';

// Default form values
const formStructure = { 
  firstname: "",
  lastname: "",
  email: "",
  permissions: [],
  warehouse_notifications: false,
  password: "",
  password_confirmation: "",
  notifications: []
}

const notificationsKeys = {
}

// Form validation schema
const formSchema = Yup.object().shape({
  firstname: Yup.string()
    .required('Obbligatorio'),
  lastname: Yup.string()
    .required('Obbligatorio'),
  email: Yup.string()
    .email()
    .required('Obbligatorio'),
  permissions: Yup.array(),
  password: Yup.string()
    .when('_id', {
      is: (_id) => _id === undefined,
      then: Yup.string()
        .required('Obbligatorio')            
    }),
  password_confirmation: Yup.string()
    .test('passwords-match', 'Le password devono essere uguali', function(value){
      return this.parent.password === value
    }),
  notifications: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().required('Scegli un modulo'),
      })
    )
});


const permissions = {
  reports : "Rapporti 📝",
  reports_administration: "Rapporti - Amministrazione 👑",
  planning: "Pianificazione 🗺️",
  warehouse: "Magazzino 📦",
  contracts: "Pre-Contratti ✍️",
  administration: "Amministrazione 👑"
}

export { formStructure, formSchema, notificationsKeys, permissions };
