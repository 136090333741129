import React from 'react';
import useSWR from 'swr'
import apiFetcher from 'utils/apiFetcher';

import { Button, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";

import FullPageError from 'components/FullPageError';
import DeleteObjectButton from 'components/DeleteObjectButton';
import ButtonJsonDebug from 'components/ButtonJsonDebug';
import CreatedUpdatedString from 'components/CreatedUpdatedString';
import { CapabilitiesContext } from 'contextes/CapabilitiesContext';
import CapabilityFence from 'components/CapabilityFence';
import PageHeader from "containers/PageHeader";
import InfoShowPartialView from './_info.show';


export default function TeamsShowView(props) {
  const { data, error } = useSWR(`core/teams/${props.match.params.id}`, apiFetcher)
  
  if (error) return <FullPageError type="error" error={error} />
  if (!data) return <div>loading...</div>

  const results = data.results;

  return (
    <CapabilitiesContext.Provider value={{ can: data.capabilities }}>

      <PageHeader
        className="has-tabs"
        title={results.name}
        meta={<CreatedUpdatedString created={results.created_at} updated={results.updated_at} deleted={results.deleted_at} />}
      >
        <ButtonJsonDebug json={data} />
          
        {!results.deleted_at &&
          <React.Fragment>
            <CapabilityFence can="update">
              <Button
                as={Link}
                to={`/administration/teams/${results._id}/edit`}
                variant="warning"
                className="mr-2 text-white"
              >
                <FaPencilAlt/> Modifica
              </Button>
            </CapabilityFence>
            
            <CapabilityFence can="destroy">
              <DeleteObjectButton
                resource="core/teams"
                resourceId={results._id}
                afterDeleteRedirect="/administration/teams"
              />
            </CapabilityFence>
          </React.Fragment>
        }
      </PageHeader>

      <Tabs defaultActiveKey="information" className="header-tabs">
        <Tab eventKey="information" title="Informazioni">
          <InfoShowPartialView data={data} />
        </Tab>
      </Tabs>
    </CapabilitiesContext.Provider>
  );
}
