import * as Yup from 'yup';

// Default form values
const formStructure = {
  city: "",
  street: "",
  lat: "",
  lon: "",
}

// Form validation schema
const formSchema = Yup.object().shape({
  city: Yup.string()
    .required('Obbligatorio'),
  street: Yup.string()
    .required('Obbligatorio'),
  lat: Yup.number(),
  lon: Yup.number()
});

export { formStructure, formSchema };
