import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react';
import googleMapsStyleNoPoi from 'utils/googleMapsStyleNoPoi';

const getMapOptions = (maps) => {
  return {
      streetViewControl: true,
      scaleControl: true,
      fullscreenControl: true,
      styles: googleMapsStyleNoPoi,
      gestureHandling: "greedy",
      disableDoubleClickZoom: true,
      //minZoom: 11,
      //maxZoom: 18,

      mapTypeControl: true,
      // mapTypeId: maps.MapTypeId.SATELLITE,
      mapTypeControlOptions: {
          // style: maps.MapTypeControlStyle.DROPDOWN_MENU,
          //position: maps.ControlPosition.BOTTOM_CENTER,
          mapTypeIds: [
              maps.MapTypeId.ROADMAP,
              maps.MapTypeId.TERRAIN,
              maps.MapTypeId.SATELLITE,
              maps.MapTypeId.HYBRID
          ]
      },

      zoomControl: true,
      clickableIcons: false
  };
};

export default function GeoJsonViewer(props) {
  const [googleApiObj, setIsGoogleApiLoadedObj] = useState(null);

  const containerStyle = {
    width: '100%',
    height: props.height || '100%'
  };

  useEffect(() => {
    if (!googleApiObj) 
      return;

    const { map } = googleApiObj;
      
    map.data.addGeoJson(props.data);
    
  }, [googleApiObj, props.data]);

  const handleApiLoaded = (map, maps) => {
    setIsGoogleApiLoadedObj({map, maps});

    map.data.setStyle({
      strokeColor: "red",
      strokeWeight: 3,
    });
  };

  return (
    <div style={containerStyle}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
        defaultCenter={props.center}
        defaultZoom={props.zoom || 16}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        options={getMapOptions}
      >
        {props.children}
      </GoogleMapReact>
    </div>
  )
}
