import Table from "rc-table";
import { Col, Row, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import useSWR from "swr";
import apiFetcher from "utils/apiFetcher";
import PaginationGeneric from "../PaginationGeneric";
import BlockEmpty from "../../BlockEmpty";
import { useState } from "react";
import WorkerRoleBadge from "../../WorkerRoleBadge";
import WorkersFilterForm from "../../Forms/WorkersFilterForm";
import { filtersObjectToString } from "utils/filters"
import { isEmpty } from "lodash";
import ErrorDebugBlock from "components/ErrorDebugBlock";

export default function  DataWorkersTable(props) {
  const [selectedPage, setSelectedPage] = useState(1);
  const [filters, setFilters] = useState({ role: "" });
  const history = useHistory();

  let apiUrl = `core/workers?page=${selectedPage}`;

  if(!isEmpty(filters))
    apiUrl += `&${filtersObjectToString(filters)}`;

  const { data, error } = useSWR(apiUrl, apiFetcher);
  
  const columns = [
    {
      title: "Nome",
      dataIndex:"firstname",
      render: (v, r) => <Link to={"/administration/workers/" + r._id}>{v} {r.lastname}</Link>
    },
    {
      title: "Ruolo",
      dataIndex:"role",
      render: v => <WorkerRoleBadge role={v}/>
    },
    {
      title: "Squadra",
      dataIndex:"team",
      render: v => <Link to={`/administration/teams/${v._id}`}>{v.name}</Link>
    },
    {
      title: "Società",
      dataIndex:"company",
      width: "30%"
    }
  ]

  if (error) return <ErrorDebugBlock error={error} />
  if (!data) return (
    <div className="text-center p-5">
      <div className="mb-3">Caricamento...</div>
      <Spinner animation="border" />
    </div>
  );

  let onRowHandler = (record) => {
    return {
      onClick: () => {},
      onDoubleClick: () => {
        history.push(`/administration/workers/${record._id}`);
      }
    }
  }

  return (
    <div className="rc-table-wrapper">

      <div className="wrapper-table">
        <Table
          columns={columns}
          data={data.results}
          emptyText={<BlockEmpty message="Nessuna Società" />}
          rowKey={record => record._id}
          className="rc-table-clickable"
          onRow={onRowHandler}
        />
      </div>

      <div className="wrapper-meta">
        <Row>
          <Col className="meta-text">
            {data.pagination.count} Operai
            
            <WorkersFilterForm onChange={setFilters} activeFilters={filters} />

          </Col>
          <Col>
            <PaginationGeneric
              pagination={data.pagination}
              selectedPage={selectedPage}
              onPageChange={setSelectedPage}
            />
          </Col>
        </Row>
      </div>

    </div>
  );
}
