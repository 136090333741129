import Table from "rc-table";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useSWR from "swr";
import apiFetcher from "utils/apiFetcher";
import BlockEmpty from "components/BlockEmpty";
import PaginationGeneric from "components/data/PaginationGeneric";
import ErrorDebugBlock from "components/ErrorDebugBlock";
import dayjs from "dayjs";
import BadgeBoolean from "components/BadgeBoolean";

export default function  DataCostsTablesTable(props) {
  const [selectedPage, setSelectedPage] = useState(1);
  const history = useHistory();

  let resourceUrl = `/core/costs_tables?page=${selectedPage}`;

  const { data, error } = useSWR(resourceUrl, apiFetcher);

  const columns = [
    {
      title: "Data",
      dataIndex:"created_at",
      render: v => <>{dayjs(v).format('DD MMMM YYYY')} <span className="text-muted">Rev.{dayjs(v).format('HHmm')}</span></>
    },
    {
      title: "Attuale",
      dataIndex:"current",
      render: v => <BadgeBoolean condition={v} />
    }
  ]

  let onRowHandler = (record) => {
    return {
      onClick: () => {},
      onDoubleClick: () => {
        history.push(`/administration/costs_tables/${record._id}`);
      }
    }
  }

  if (error) return <ErrorDebugBlock error={error} />
  if (!data) return (
    <div className="text-center p-5">
      <div className="mb-3">Caricamento...</div>
      <Spinner animation="border" />
    </div>
  );

  return (
    <div className="rc-table-wrapper">
      <div className="wrapper-table">
        <Table
          columns={columns}
          data={data.results}
          emptyText={<BlockEmpty message="Nessuna tabella costi" />}
          rowKey={record => record._id}
          className="rc-table-clickable"
          onRow={onRowHandler}
        />
      </div>
      
      <div className="wrapper-meta">
        <PaginationGeneric
          pagination={data.pagination}
          selectedPage={selectedPage}
          onPageChange={setSelectedPage}
        />
      </div>
    </div>
  );
}
