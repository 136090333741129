import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { FaPlus, FaClone } from "react-icons/fa";
import { useHistory } from "react-router";
import useApiMutator from "utils/apiMutator";

export default function  CostsTablesNewButton(props) {
  // eslint-disable-next-line
  const [mutate, mutationError]= useApiMutator("core/costs_tables");
  const [modalStatus, setModalStatus] = useState(false);
  const [action, setAction] = useState("new");
  const history = useHistory();


  /**
   * 
   */
  const handleCreation = async () => {
    const payload = { costs_table: { clone: false } }

    if(action === "clone")
      payload.costs_table.clone = true;

    const data = await mutate(payload);

    if(data)
      history.push(`/administration/costs_tables/${data.results._id}`);
  }

  /**
   * 
   */
  const openModal = (requested_action = "new") => {
    setModalStatus(!modalStatus);

    setAction(requested_action);
  }

  /**
   * 
   */
   const closedModal = () => {
    setModalStatus(!modalStatus);
  }

  const modal = (
    <Modal
      show={modalStatus}
      onHide={closedModal}
    >
      <Modal.Header>
        <Modal.Title>Conferma creazione</Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <p>Sei sicuro di voler creare una nuova tabella costi?</p>
        <p className="mb-0">Questa operazione non è reversibile!</p>

      </Modal.Body>

      <Modal.Footer>
        <Button variant="success" className="mr-2" onClick={() => handleCreation(action)}>Crea</Button>

        <Button variant="outline-secondary" onClick={closedModal}>Annulla</Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <React.Fragment>
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <FaPlus style={{marginTop: "-2px"}} /> Crea
        </Dropdown.Toggle>

        <Dropdown.Menu>

          <Dropdown.Item onClick={() => openModal()}>
            <FaPlus style={{marginTop: "-3px"}} /> Crea tabella vuota
          </Dropdown.Item>

          <Dropdown.Item onClick={() => openModal("clone")}>
            <FaClone style={{marginTop: "-3px"}} /> Clona tabella attiva
          </Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>

      {modal}
      
    </React.Fragment>
  );
}
