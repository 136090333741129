import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import AuthContext from "contextes/AuthContext";

import LoginView from "views/login";

import AppLayout from "views/layouts/App";

import FullPageError from "components/FullPageError";

import DashboardView from "views/dashboard";

import PlanningRoutesGroup from "views/planning";
import AdministrationRoutesGroup from "views/administration";
import ReportingRoutesGroup from "views/reporting";

function App() {
  const [user, setUser] = useState();
  
  if(!user)
    return <LoginView onLogin={ setUser } />;
  
  return (
    <AuthContext.Provider value={{user, setUser}}>
      <Router>
        <AppLayout>

          <Switch>          

            
            <Route path="/planning" component={PlanningRoutesGroup} />
            <Route path="/reporting" component={ReportingRoutesGroup} />
            <Route path="/administration" component={AdministrationRoutesGroup} />

            
            
            <Route exact path="/" component={DashboardView} />

            <Route>
              <FullPageError type="error" title="Questa pagina non esiste!"/>
            </Route>
          </Switch>

        </AppLayout>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;

