import { Formik } from 'formik';
import { Button, Form, Spinner} from 'react-bootstrap';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function FormWrapper(props) {

  return (
    <Formik
      onSubmit={props.onSubmit}
      validationSchema={props.validationSchema}
      initialValues={props.initialValues}
    >
      {(formik) => (   
        <Form noValidate onSubmit={formik.handleSubmit} style={props.style}>

          {props.children}

          <div className="text-right">
            <Button
              type="submit"
              variant="primary"
              className="mr-2"
              disabled={props.isDisabled || props.isLoading}
            >
              {props.isLoading && <Spinner animation="border" size="sm" className="mr-2" />}
              {props.submitLabel ? props.submitLabel : "Salva"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
