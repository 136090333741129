import { useState } from "react";


/**
 * 
 * @param {String} Resource The backend endpoint
 * @returns 
 */
export default function useApiMutator(resource) {
  const [error, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  
  /**
   * Send an object to the backend to update or create it
   * 
   * @param {Object} values The values to send to the backend
   * @returns 
   */
  const mutate = async (values, options) => {
    let id = false;
    let headers = { "Accept": "application/json" };

    options = {
      format: "json",
      ...options
    };

    setError(false);
    setIsSaving(true);

    const modelName = Object.keys(values)[0]

    if(values[modelName]._id) {
      id = values[modelName]._id;
      resource += `/${id}`;
    }

    switch (options.format) {
      case "formdata":
      case "multipart":
        const formData  = new FormData();

        for ( const key in values[modelName] )
          formData.append(`[${modelName}]${key}`, values[modelName][key]);
        
        if(options.format === "multipart")
          headers["Content-type"] = "multipart/form-data";

        values = formData;
        break;

      case "json":
        headers["Content-type"] = "application/json; charset=UTF-8";
        values = JSON.stringify(values);
        break;

      default:
        throw new Error("Invalid API mutation format. It must be one of [json, formdata, multipart].");
    }

    try {
      const res = await fetch(process.env.REACT_APP_BACKEND_URL + resource, {
        method: id ? "PATCH" : "POST",
        headers,
        body: values,
        credentials: "include"
      });

      // Throw an error if the server returned anything but 200 OK
      if (!res.ok) {
        let error = (await res.json())
        
        error = error.error ? error.error.message : error.message;
    
        throw new Error("Errore del server: " + error)
      }

      setIsSaving(false);
      return await res.json();

    } catch(e) {
      setIsSaving(false);
      setError(e.message);
      return false;
    }
  }

  return [mutate, error, isSaving];
}
