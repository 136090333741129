import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { BiPlus } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import PageHeader from 'containers/PageHeader';
import PlanningIndexContext from 'contextes/PlanningIndexContext';
import ConstructionSitesTable from 'components/data/planning/sections/ConstructionSitesTable';

export default function ConstructionSiteIndexView() {
  const [activeSection, setActiveSection] = useState(false);

  const contextValue = {
    document: { active: activeSection, set: setActiveSection }
  };

  return (
    <PlanningIndexContext.Provider value={contextValue}>

      <PageHeader title="Cantieri">
        <Button
          as={Link}
          to="/planning/construction_sites/new"
          variant="success"
        >
          <BiPlus/> Crea
        </Button>
      </PageHeader>

      <div className="content-container">
        <Row noGutters style={{height: "100%" }}>
          <Col style={{height: "100%" }}>
            <ConstructionSitesTable />
          </Col>
        </Row>
      </div>


    </PlanningIndexContext.Provider>
  );
}
