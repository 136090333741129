import React from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import FullPageError from 'components/FullPageError';

import UsersIndexView from './users';
import UsersEditView from './users/edit';
import UsersShowView from './users/show';

import CostsTablesIndexView from './costsTables';
import CostsTablesShowView from './costsTables/show';
import CostsTablesEditView from './costsTables/edit';

import WorkersEditView from './workers/edit';
import WorkersIndexView from './workers';
import WorkersShowView from './workers/show';

import TeamsShowView from './teams/show';
import TeamsEditView from './teams/edit';
import TeamsIndexView from './teams';

import OptionsIndexView from './options';
import OptionsShowView from './options/show';
import OptionsEditView from './options/edit';
import MaterialsIndexView from './materials';


export default function AdministrationRoutesGroup() {
  const { path } = useRouteMatch();

  return (
    <React.Fragment>
      <Switch>

        <Route path={`${path}/users`} component={UsersIndexView} exact />
        <Route path={`${path}/users/new`} component={UsersEditView} />
        <Route path={`${path}/users/:id/edit`} component={UsersEditView} />
        <Route path={`${path}/users/:id`} component={UsersShowView} />

        <Route path={`${path}/costs_tables`} component={CostsTablesIndexView} exact />
        <Route path={`${path}/costs_tables/:id/edit`} component={CostsTablesEditView} />
        <Route path={`${path}/costs_tables/:id`} component={CostsTablesShowView} />

        <Route path={`${path}/materials`} component={MaterialsIndexView} exact />

        <Route path={`${path}/workers`} component={WorkersIndexView} exact/>
        <Route path={`${path}/workers/new`} component={WorkersEditView}/>
        <Route path={`${path}/workers/:id/edit`} component={WorkersEditView}/>
        <Route path={`${path}/workers/:id`} component={WorkersShowView}/>

        <Route path={`${path}/teams`} component={TeamsIndexView} exact/>
        <Route path={`${path}/teams/new`} component={TeamsEditView}/>
        <Route path={`${path}/teams/:id/edit`} component={TeamsEditView}/>
        <Route path={`${path}/teams/:id`} component={TeamsShowView}/>

        <Route path={`${path}/options`} component={OptionsIndexView} exact/>
        <Route path={`${path}/options/:id/edit`} component={OptionsEditView}/>
        <Route path={`${path}/options/:id`} component={OptionsShowView}/>
        
        
        <Route path={`${path}`}>
          <FullPageError type="error" title="Questa pagina non esiste!"/>
        </Route>
      </Switch>
    </React.Fragment>
  );
}
