import { createContext } from "react";

/**
 * Holds the information about the currently logged in user
 */
const AuthContext = createContext({
  user: false,
  setUser: () => {}
});

export default AuthContext;
