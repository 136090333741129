import Table from "rc-table";
import BlockEmpty from "components/BlockEmpty";
import unitsOfMeasure from "utils/constants/unitsOfMeasure";
import AuthContext from "contextes/AuthContext";
import { useContext } from "react";
import { intersection } from "lodash-es";

export default function  DataCostsTablesMaterialsTable(props) {
  const  { user } = useContext(AuthContext);

  const columns = [
    {
      title: "Nome",
      dataIndex:"material",
      render: v => v.name
    }
  ]

  if(intersection(["economics", "administration"], user.permissions).length !== 0)
    columns.push({
      title: "Unità",
      dataIndex:"material",
      width: "25%",
      render: (v, r) => `${r.cost} CHF/${unitsOfMeasure[v.unit]}`
    });

  return (
    <Table
      columns={columns}
      data={props.data}
      emptyText={<BlockEmpty message="Nessun Materiale" />}
      rowKey={record => record._id}
    />
  );
}
