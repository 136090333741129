import React, { useEffect, useState } from 'react';
import PageHeader from '../../../containers/PageHeader';
import GoogleMapReact from 'google-map-react';
import FullPageLoading from 'components/FullPageLoading';
import ErrorDebugBlock from 'components/ErrorDebugBlock';
import useSWR from 'swr';
import apiFetcher from 'utils/apiFetcher';
import { MapMarker } from 'components/MapMarker';
import { Popover } from 'react-bootstrap';
import { LinkConstructionSite } from 'components/ResourceLinks';
import SimplePropertiesTable, { TableRow } from 'components/SimplePropertiesTable';
import googleMapsStyleGray from 'utils/googleMapsStyleGray';
import constructionSiteTypologies from 'utils/constants/constructionSiteTypologies';
import roofTypologies from 'utils/constants/roofTypologies';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 46.8585107,
  lng: 8.3885834
};

const getMapOptions = (maps) => {
  return {
      streetViewControl: true,
      scaleControl: true,
      fullscreenControl: true,
      styles: googleMapsStyleGray,
      gestureHandling: "greedy",
      disableDoubleClickZoom: true,
      //minZoom: 11,
      //maxZoom: 18,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
          // style: maps.MapTypeControlStyle.DROPDOWN_MENU,
          //position: maps.ControlPosition.BOTTOM_CENTER,
          mapTypeIds: [
              maps.MapTypeId.ROADMAP,
              maps.MapTypeId.TERRAIN,
              maps.MapTypeId.SATELLITE,
              maps.MapTypeId.HYBRID
          ]
      },

      zoomControl: true,
      clickableIcons: false
  };
};

export default function ConstructionSitesMapView(props) {
  const [googleApiObj, setIsGoogleApiLoadedObj] = useState(null);
  const { data, error } = useSWR("planning/tools/customer_sections_map", apiFetcher);

  useEffect(() => {
    if (!googleApiObj) 
      return;

    const { map } = googleApiObj;
      
    map.data.loadGeoJson("/api/v1/planning/sections.geojson?sort_field=city,asc");
    
  }, [googleApiObj, props.data]);

  const handleApiLoaded = (map, maps) => {
    setIsGoogleApiLoadedObj({map, maps});

    map.data.setStyle({
      strokeColor: "red",
      strokeWeight: 3,
    });
  };

  if (error) return <ErrorDebugBlock title={error.message} />;
  if (!data) return <FullPageLoading />;

  const apiData = data.results;
  return (
    <React.Fragment>
      <PageHeader title="Mappa Cantieri" />

      <div className="content-container">
        <div style={containerStyle}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
            defaultCenter={center}
            defaultZoom={9}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            options={getMapOptions}
          >
            {apiData.construction_sites.map( s => (
              <MapMarker lat={s.address.lat} lng={s.address.lon} key={s._id}>
                  <Popover.Title as="h3">
                    <LinkConstructionSite resource={s} />
                  </Popover.Title>
                  <Popover.Content className="p-0">

                    <SimplePropertiesTable>
                      <TableRow label="Città">{s.address.city}</TableRow>
                      <TableRow label="Via">{s.address.street}</TableRow>
                      <TableRow label="Tipologia">{constructionSiteTypologies[s.typology]}</TableRow>
                      <TableRow label="Tetto">{roofTypologies[s.roof_typology]}</TableRow>
                      <TableRow label="Kw">{s.kilowatts}</TableRow>
                    </SimplePropertiesTable>

                  </Popover.Content>
              </MapMarker>
            ))}
          </GoogleMapReact>
        </div>
      </div>
    </React.Fragment>
  );
}