import { Formik, Form, Field } from 'formik';
import { useEffect, useState } from 'react';
import { Button,Spinner } from 'react-bootstrap';

import { authLogin, authFetch } from '../utils/auth';

// Assets
import logo from '../assets/images/youpower-logo.png';

/**
 * Renders the login screen and authenticates the user if necessary
 * 
 * @returns {React.FunctionComponent} 
 */
export default function LoginView(props) {
  const [prefetchRunning, setPrefetchRunning] = useState(true);
  const [error, setError] = useState(false);

  /**
   * 
   * @param {Object} values
   * @param {Object} { setSubmitting }
   */
  const handleLogin = async (values, { setSubmitting }) => {
    setError(false);

    try {
      const res = await authLogin(values);

      setSubmitting(false);
      props.onLogin(res);
    } catch(e) {
      setSubmitting(false);
      setError(e.message);
    }
  }

  /**
   * It's a bit weird to define and run a function inside in other but useEffect doesn't support
   * async callbacks directly due to race conditions.
   */
  useEffect(() => {
    /**
     * Tests if the user is already logged-in by loading its information from the backend,
     * skipping the login screen if the request is successful.
     */
    const prefetch = async () => {
      try {
        const res = await authFetch();

        props.onLogin(res);
      } catch (e) {
        setPrefetchRunning(false);
      }
    }

    prefetch();
  }, [props]);

  if(prefetchRunning)
    return (
      <div className="login-form" style={{flexDirection: "column"}}>
        <h1>Umarell</h1>
        <Spinner animation="border" size="lg"/>
      </div>
    )
    
  return (
    <div className="login-form">
      <Formik
        onSubmit={handleLogin}
        initialValues={{ email: "", password: "" }}
      >

        {(formik) => (  

          <Form noValidate onSubmit={formik.handleSubmit} className="form-signin">

            <img className="mb-4" src={logo} alt="YouPwer" height="25" />
            
            <h1 className="h3 mb-3 font-weight-normal">Accedi a Solaris</h1>

            {error && <div className="alert alert-danger">{error}</div>}

            <Field type="email" name="email" className="form-control" placeholder="Email" />
            <Field type="password" name="password" className="form-control" placeholder="Password" />   

            <Button
              variant="primary"
              block
              size="lg"
              type="submit" 
              disabled={formik.isSubmitting}
            >
                {formik.isSubmitting ? <Spinner as="span" size="sm" animation="border"/> : "Accedi"}
            </Button>

            <p className="mt-5 mb-3 text-muted">
              Made with ❤️ by &nbsp;
              <a target="_blank" className="text-muted" rel="noreferrer nofollow" href="https://intercom.it">
                intercom s.r.l.
              </a>
            </p>
          </Form>

        )}

      </Formik>
    </div>
  );
}
