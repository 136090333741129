import * as Yup from 'yup';

// Default form values
const formStructure = {
  cost: 0
}


// Form validation schema
const formSchema = Yup.object().shape({
  cost: Yup.number()
    .min(0)
    .required('Obbligatorio'),
});

export { formStructure, formSchema };
