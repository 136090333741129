import React from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { BiEditAlt, BiLinkExternal } from "react-icons/bi";
import { Col, Row, Button, ListGroup, Container } from "react-bootstrap";

import apiFetcher from "utils/apiFetcher";
import CreatedUpdatedString from "components/CreatedUpdatedString";
import FullPageError from "components/FullPageError";
import BootstrapCard from "components/BootstrapCard"; 
import ButtonJsonDebug from "components/ButtonJsonDebug";
import ListGroupItemSplit from "components/ListGroupItemSplit";
import CapabilityFence from "components/CapabilityFence";
import DeleteObjectButton from "components/DeleteObjectButton";
import { CapabilitiesContext } from 'contextes/CapabilitiesContext';
import PageHeader from "containers/PageHeader";
import BadgeBoolean from "components/BadgeBoolean";
import dayjs from "dayjs";
import ConstructionSitesWorkersTable from "components/data/planning/sections/ConstructionSitesWorkersTable";
import ConstructionSiteMaterialsTable from "components/data/planning/sections/ConstructionSiteMaterialsTable";
import GeoJsonViewer from "components/GeoJsonViewer";

export default function WorkReportsShowView(props) {
  const { data, error } = useSWR(`reporting/work_reports/${props.match.params.id}`, apiFetcher)

  if (error) return <FullPageError type="error" error={error} />
  if (!data) return <div>loading...</div>
  
  const apiData = data.results;
  return (
    <CapabilitiesContext.Provider value={{ can: data.capabilities }}>

        <PageHeader
          title={apiData.name}
          meta={<CreatedUpdatedString created={apiData.created_at} updated={apiData.updated_at} deleted={apiData.deleted_at}/>}
        >
          <ButtonJsonDebug json={data} />
               
          {!apiData.deleted_at &&
            <React.Fragment>
              <CapabilityFence can="update">
                <Button as={Link} to={`/reporting/work_reports/${apiData._id}/edit`} className="mr-2" variant="warning">
                  <BiEditAlt/> Modifica
                </Button>
              </CapabilityFence>
              
              <CapabilityFence can="destroy">
                <DeleteObjectButton
                  resource="reporting/work_reports"
                  resourceId={apiData._id}
                  afterDeleteRedirect="/reporting/work_reports"
                />
              </CapabilityFence>
            </React.Fragment>
          }

        </PageHeader>
        
        <Container fluid className="content-container pt-3 pb-3">
          <Row>
            <Col>

              <Row>
                <Col>
                  <BootstrapCard header="Informazioni Base" noPadding>
                    <ListGroup variant="flush">

                      <ListGroupItemSplit label="Data cantiere">
                        {dayjs(apiData.executed_at).format('DD/MM/YYYY')}
                      </ListGroupItemSplit>
                    
                      <ListGroupItemSplit label="Tratta">
                        <Link to={`/planning/sections/${apiData.section._id}`}>
                          <code>{apiData.section.code}</code>
                        </Link>
                      </ListGroupItemSplit>

                      <ListGroupItemSplit label="Capo Cantiere">
                        {apiData.foreman && <Link to={`/administration/workers/${apiData.foreman._id}`}>{apiData.foreman.name}</Link>}
                      </ListGroupItemSplit>

                      <ListGroupItemSplit label="Posizione">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://google.com/maps/search/${apiData.section.address.lat},${apiData.section.address.lon}`}
                        >
                          {apiData.section.address.street}, {apiData.section.address.city} <BiLinkExternal color="#aaa" style={{marginTop: "-2px"}}/>
                        </a>
                      </ListGroupItemSplit>

                    </ListGroup>
                  </BootstrapCard>
                </Col>
                <Col>
                  <BootstrapCard header="Informazioni Lavoro" noPadding>
                    <ListGroup variant="flush">

                      <ListGroupItemSplit label="Ore lavorate">{apiData.hours}</ListGroupItemSplit>
                      <ListGroupItemSplit label="Scavo eseguito">{apiData.dig_length} Metri</ListGroupItemSplit>
                      <ListGroupItemSplit label="Scavo chiuso">{apiData.completed_length} Metri</ListGroupItemSplit>
                      
                    </ListGroup>
                  </BootstrapCard>
                </Col>
              </Row>

              <BootstrapCard header="Operai" noPadding>
                <ConstructionSitesWorkersTable data={apiData.section_workers}/>
              </BootstrapCard>

              <BootstrapCard header="Materiali" noPadding>
                <ConstructionSiteMaterialsTable data={apiData.section_materials}/>
              </BootstrapCard>
            
            </Col>
            <Col md="4">

              <BootstrapCard noPadding>
                <GeoJsonViewer
                  center={{lat: apiData.section.address.lat, lng: apiData.section.address.lon}}
                  height="440px"
                />
              </BootstrapCard>

              <BootstrapCard header="Varie" noPadding>
                <Row noGutters>
                  <Col className="border-right p-2 pl-3 border-top bg-light">
                    Ripristino
                  </Col>
                  <Col className="p-2 pl-3 border-top">
                    <BadgeBoolean condition={apiData.restoration} />
                  </Col>
                </Row>
              </BootstrapCard>

              <BootstrapCard header="Annotazioni">
                {apiData.annotations ? apiData.annotations : <span className="text-muted">Nessuna annotazione</span>}
              </BootstrapCard>
            </Col>
          </Row>
        </Container>

    </CapabilitiesContext.Provider>
  );
}