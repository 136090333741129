import React from 'react';
import useSWR from 'swr'
import apiFetcher from 'utils/apiFetcher';

import {  Row, Col, Button, ListGroup, Alert, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";

import FullPageError from 'components/FullPageError';
import DeleteObjectButton from 'components/DeleteObjectButton';
import ButtonJsonDebug from 'components/ButtonJsonDebug';
import WorkerRoleBadge from 'components/WorkerRoleBadge';
import Identicon from 'components/Identicon';
import CreatedUpdatedString from 'components/CreatedUpdatedString';
import ListGroupItemSplit from 'components/ListGroupItemSplit';
import { CapabilitiesContext } from 'contextes/CapabilitiesContext';
import CapabilityFence from 'components/CapabilityFence';
import PageHeader from 'containers/PageHeader';
import BootstrapCard from 'components/BootstrapCard';


export default function WorkersShowView(props) {
  const { data, error } = useSWR(`core/workers/${props.match.params.id}`, apiFetcher)
  
  if (error) return <FullPageError type="error" error={error} />
  if (!data) return <div>loading...</div>

  const results = data.results;

  return (
    <CapabilitiesContext.Provider value={{ can: data.capabilities }}>

      <PageHeader
        title={`${results.firstname} ${results.lastname}`}
        meta={<CreatedUpdatedString created={results.created_at} updated={results.updated_at} deleted={results.deleted_at} />}
      >
        <ButtonJsonDebug json={data} />
          
        {!results.deleted_at &&
          <React.Fragment>
            <CapabilityFence can="update">
              <Button
                as={Link}
                to={`/administration/workers/${results._id}/edit`}
                variant="warning"
                className="mr-2 text-white"
              >
                <FaPencilAlt/> Modifica
              </Button>
            </CapabilityFence>
            
            <CapabilityFence can="destroy">
              <DeleteObjectButton
                resource="core/workers"
                resourceId={results._id}
                afterDeleteRedirect="/administration/workers"
              />
            </CapabilityFence>
          </React.Fragment>
        }
      </PageHeader>
      
      <Container fluid className="content-container pt-3 pb-3">
        {results.deleted_at && <Alert variant="warning" className="mt-3">Questo operaio è stato eliminato</Alert>}

        <Row>
          <Col>

            <BootstrapCard header="Informazioni" noPadding>
              <ListGroup variant="flush">

                <ListGroupItemSplit label="Nome">
                  {results.firstname} {results.lastname}
                </ListGroupItemSplit>

                <ListGroupItemSplit label="Squadra">
                  <Link to={`/administration/teams/${results.team._id}`}>{results.team.name}</Link>
                </ListGroupItemSplit>

                <ListGroupItemSplit label="Ruolo">
                  <WorkerRoleBadge role={results.role}/>
                </ListGroupItemSplit>
                
              </ListGroup>
            </BootstrapCard>

          </Col>

          <Col md="4">

            <BootstrapCard header="Identicon" noPadding>
              <Identicon md5={results._id} size="500"/>
            </ BootstrapCard>

          </Col>
        </Row>
      </Container>
    </CapabilitiesContext.Provider>
  );
}
