/**
 * 
 * @param {*} filters 
 * @returns 
 */
function filtersObjectToString(filters) {
  let filtersString = "";

  Object.keys(filters).forEach(elm => {
    const filterValue =  filters[elm] instanceof Array ? filters[elm].join(',') : filters[elm];

    filtersString += `${elm}=${filterValue};`
  });


  return filtersString.slice(0, -1);
}

export { filtersObjectToString }
