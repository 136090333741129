import * as Yup from 'yup';

import { formSchema as materialsSchema} from './costs_table_materials';
import { formSchema as workersSchema} from './costs_table_workers';

// Default form values
const formStructure = { 
  incidences_general: 0,
  incidences_management: 0,
  costs_table_workers: [],
  costs_table_materials: []
}


// Form validation schema
const formSchema = Yup.object().shape({
  incidences_general: Yup.number()
    .min(0)
    .required('Obbligatorio'),
  incidences_management: Yup.number()
    .min(0)
    .required('Obbligatorio'),
  costs_table_materials: Yup.array()
    .min(1, "Devi inserire almeno un lavoro")
    .of(materialsSchema)
    .required('Obbligatorio'),
  costs_table_workers: Yup.array()
    .min(1, "Devi inserire almeno un lavoro")
    .of(workersSchema)
    .required('Obbligatorio'), 
});

export { formStructure, formSchema };
