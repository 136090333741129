import React, { useEffect, useState } from "react";

import { useFormikContext } from "formik";
import { Table, Alert, Button } from "react-bootstrap";
import TableInputText from "../Inputs/TableInputText";
import unitsOfMeasure from "utils/constants/unitsOfMeasure";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import TableInputSelectCostsMaterial from "components/Inputs/TableInputSelectCosts";
import apiFetcher from "utils/apiFetcher";
import useSWR from "swr";
import { formStructure } from "forms/planning/SectionCostsMaterial";
import { cloneDeep } from "lodash-es";
import BlockEmpty from "components/BlockEmpty";


/**
 * Renders a sub form to edit works entries
 * This component can only be rendered inside the reports edit form
 * 
 * @param {*} props 
 * @returns 
 */
export default function MaterialsCostsSubform(props) {
  const formik = useFormikContext();
  const [materials, setMaterials] = useState([]);
  const { data, error } = useSWR("core/costs_tables/current", apiFetcher);

  useEffect(() => {
    if(data) {
      let materials_row = data.results.costs_table_materials;
      materials_row = materials_row.map(e => { return { _id: e.material._id, name: e.material.name } });

      setMaterials(materials_row);
    }
  }, [data]);

  if (error) return <div>Error</div>
  if (!data) return <div>loading...</div>

  let elements = formik.values[props.name];

  /**
   * Adds a work into the form values
   */
  const addElement = () => {
    const wl = cloneDeep(formStructure);
  
    elements.push(wl);
    formik.setFieldValue(props.name, elements);
  }

  /**
   * Removes a work from the form values by index
   * 
   * @param {Number} index 
   */
   const removeElement = (index) => {
    elements.splice(index, 1);
    formik.setFieldValue(props.name, elements);
  }

  return (
    <React.Fragment>

      <div style={{position: "absolute", top: "8px", right: "1.25rem"}}>
        <Button
          variant="secondary"
          size="sm"
          onClick={addElement}
          disabled={elements.length >= materials.length}
        >
          <FaPlus /> Aggiungi Materiale
        </Button>
      </div>

      {elements.length === 0 ? <BlockEmpty message="Nessun Materiale" /> : (  
        <Table className="inputTable mb-0">

          <thead>
            <tr>
              <th>Nome</th>
              <th width="15%">Quantità</th>
              <th width="15%">Unità</th>
              <th width="7%"></th>
            </tr>
          </thead>

          <tbody>
            {elements.map((e, i) => (

              <tr key={i}>
                <td>
                  <TableInputSelectCostsMaterial name={props.name + '.' + i + '.material'} values={materials} />
                </td>

                <td>
                  <TableInputText type="number" name={props.name + '.' + i + '.quantity'} min="0" />
                </td>

                <td>
                  <span className="pl-2" style={{lineHeight: "50px"}}>{unitsOfMeasure[e.material.unit]}</span>
                </td>

                <td>
                  <Button variant="danger" size="sm"  className="btn-xs" onClick={() => { removeElement(i)} }><FaTrashAlt /></Button>
                </td>

              </tr>

            ))}
          </tbody>

        </Table>
      )}

      {formik.errors.works && typeof formik.errors.works === 'string' && (
        <Alert variant="danger" className="m-3">{formik.errors.works}</Alert>
      )}
    </React.Fragment>
  );
}
