import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";

import PermissionFence from '../PermissionFence';

/**
 * Renders a dropdown with links to the user's profile
 * 
 * @returns {React.FunctionComponent}
 */
export default function HeaderMenu() {

  return (
    <Nav className="mr-auto">

      <PermissionFence mustHave={["planning", "contracts"]}>
        <NavDropdown title="Cantieri" id="basic-nav-dropdown" className="dropdown-large">

          <NavDropdown.Header>Cantieri</NavDropdown.Header>

          <PermissionFence mustHave={["planning"]}>
            <NavDropdown.Item as={Link} to="/planning/construction_sites">Gestione Cantieri</NavDropdown.Item>
          </PermissionFence>


          <PermissionFence mustHave={["planning"]}>
            <PermissionFence mustHave={["contracts"]}>

              <NavDropdown.Divider />
              <NavDropdown.Header>Strumenti</NavDropdown.Header>

              <NavDropdown.Item as={Link} to="/planning/tools/construction_sites_map">Mappa cantieri</NavDropdown.Item>
            </PermissionFence>
          </PermissionFence>

        </NavDropdown>
      </PermissionFence>

      

      <PermissionFence mustHave={["reports_administration", "economics", "planning"]}>
        <NavDropdown title="Amministrazione" id="basic-nav-dropdown" className="dropdown-large">

          <PermissionFence mustHave={["reports_administration", "economics", "planning"]}>
            
            <NavDropdown.Header>Amministrazione</NavDropdown.Header>
            <PermissionFence>
              <NavDropdown.Item as={Link} to="/administration/options">Opzioni</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/administration/users">Utenti</NavDropdown.Item>
            </PermissionFence>

            <NavDropdown.Divider />

            <PermissionFence mustHave={["economics"]}>
              <NavDropdown.Header>Costi</NavDropdown.Header>
              <NavDropdown.Item as={Link} to="/administration/costs_tables">Tabelle Costi</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/administration/materials">Materiali</NavDropdown.Item>
              <NavDropdown.Divider />
            </PermissionFence>

          </PermissionFence>

          <NavDropdown.Header>Personale</NavDropdown.Header>
          <NavDropdown.Item as={Link} to="/administration/workers">Operai</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/administration/teams">Squadre</NavDropdown.Item>

        </NavDropdown>
      </PermissionFence>

    </Nav>
  );
};

/*

  <NavDropdown.Header>Rapporti</NavDropdown.Header>
  <NavDropdown.Item as={Link} to="/reporting/work_reports">
    Rapporti cantiere
  </NavDropdown.Item>
*/