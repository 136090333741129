import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import React from "react";
import PageHeader from "containers/PageHeader";
import DataAdUsersTable from "components/data/administration/UsersTable";

export default function UsersIndexView() {

  return (
    <React.Fragment>

      <PageHeader title="Utenti">
        <Button as={Link} to="/administration/users/new" variant="success"><FaPlus/> Crea</Button>
      </PageHeader>
      
      <div className="content-container">
        <DataAdUsersTable />
      </div>
    </React.Fragment>
  );
}
