import I_MapPinPrimary from 'assets/images/map-pin-primary.png'
import { useState } from 'react';
import { Popover } from 'react-bootstrap';

export function MapMarker(props) {
  const [show, setShow] = useState(false);

  const popover = (
    <Popover style={{marginTop: "-40px", marginLeft: "25px", width: "250px", zIndex: 999 }}>
      {props.children}
    </Popover>
  );

  if(!props.children)
    return (
        <img
          src={I_MapPinPrimary}
          style={{height: "42px", transform: "translate(-50%, -100%)", cursor: "pointer"}}
          alt=""
        />
    );

  return (
    <div>
      <img
          src={I_MapPinPrimary}
          style={{height: "42px", transform: "translate(-50%, -100%)", cursor: "pointer"}}
          alt=""
          onClick={() => setShow(!show)}
        />

      {show && popover}
    </div>
  )
}