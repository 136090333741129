import { Link } from "react-router-dom";
import TableGeneric from "components/data/TableGeneric";
import Identicon from "components/Identicon";
import UserPermissionsList from "components/UserPermissionsList";

const columns = [
  {
    title: "Nome",
    dataIndex:"firstname",
    key: "firstname",
    width: "55%",
    render: (v, r) => (
      <Link to={"/administration/users/" + r._id}>
        <Identicon
          md5={r.email_hash}
          provider="gravatar"
          size="18"
          className="rounded border mr-1"
          style={{marginTop: "-2px"}}
        /> 
        {r.firstname} {r.lastname}
      </Link>
    )
  },
  {
    title: "Permessi",
    dataIndex:"permissions",
    key: "permissions",
    width: "20%",
    render: v => <UserPermissionsList permissions={v} summary />
  }
]

export default function  DataFbSectionsTable(props) {
  
  return (
    <TableGeneric 
      columns={columns}
      resource="users"
      rowLinkLocation="administration/users"
      defaultSort={["created_at", "asc"]}
    />
  );
}
