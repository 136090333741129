import useSWR, { useSWRConfig } from 'swr';
import apiFetcher from 'utils/apiFetcher';
import useApiMutator from 'utils/apiMutator';
import { useHistory } from "react-router";

import { formStructure, formSchema } from "forms/administration/worker";

import { Row, Col, Container, Alert } from "react-bootstrap";
import FormWrapper from 'components/Forms/FormWrapper';
import FullPageError from 'components/FullPageError';
import BootstrapCard from 'components/BootstrapCard';
import RowInput from 'containers/RowInput';
import InputTeamSelect from 'components/Inputs/InputTeamSelect';
import { cloneDeep, identity, pickBy } from 'lodash-es';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function WorkersEditView(props) {
  const history = useHistory();
  const { mutate } = useSWRConfig();
  const id = props.match.params.id;

  const { data, error } = useSWR(id ? `core/workers/${id}` : null, apiFetcher);
  const [mutateApi, mutationError]= useApiMutator("core/workers");

  /**
   * 
   * @param {*} values 
   */
  const handleSubmit = async (values) => {
      const data = await mutateApi({ worker: values });

      // Triggers a data revalidation
      if(id)
        mutate(`core/workers/${id}`);

      if(data)
        history.replace(`/administration/workers/${data.results._id}`);
  }

  let initialValues = cloneDeep(formStructure);

  if(data?.results) {
    initialValues = {...initialValues, ...pickBy(data.results, identity)}

    initialValues.team = initialValues.team._id;
    initialValues.company = initialValues.company._id;
  }

  if (error) return <FullPageError type="error" error={error} />
  if (id && !data) return <div>loading...</div>

  return (
    <Container fluid className="content-container pt-3 pb-3">
      <Row className="justify-content-md-center">
        <Col md="6">
          <h3 className="mb-3">
            {data ? `Modifica ${data.results.firstname} ${data.results.lastname}` : "Aggiungi Operaio" }
          </h3>

          {mutationError && <Alert variant="danger">{mutationError}</Alert>}

          <FormWrapper
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
  
            <BootstrapCard header="Informazioni di base">

                <RowInput label="Nome" type="text" name="firstname" />

                <RowInput label="Cognome" type="text" name="lastname" />

                <RowInput label="Ruolo" type="select" name="role">
                  <option value="generic">Generico</option>
                  <option value="foreman">Capo Cantiere</option>
                  <option value="mechanic">Meccanico</option>
                  <option value="external">Esterno</option>
                </RowInput>

                <hr />

                <RowInput label="Squadra" name="team">
                  <InputTeamSelect name="team" />
                </RowInput>
            </BootstrapCard>

          </FormWrapper>
        </Col>
      </Row>
    </Container>
  );
}
