import React from 'react';
import useSWR from 'swr'
import {  Row, Col, Button, ListGroup, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import apiFetcher from 'utils/apiFetcher';
import FullPageError from 'components/FullPageError';
import ButtonJsonDebug from 'components/ButtonJsonDebug';
import CreatedUpdatedString from 'components/CreatedUpdatedString';
import ListGroupItemSplit from 'components/ListGroupItemSplit';
import BootstrapCard from 'components/BootstrapCard';
import PageHeader from 'containers/PageHeader';
import { CapabilitiesContext } from 'contextes/CapabilitiesContext';
import CapabilityFence from 'components/CapabilityFence';
import FullPageLoading from 'components/FullPageLoading';


export default function OptionsShowView(props) {
  const { data, error } = useSWR(`core/options/${props.match.params.id}`, apiFetcher)
  
  if (error) return <FullPageError type="error" error={error} />
  if (!data) return <FullPageLoading />

  const apiData = data.results;

  return (
    <CapabilitiesContext.Provider value={{ can: data.capabilities }}>

      <PageHeader
        title={apiData.key}
        meta={<CreatedUpdatedString created={apiData.created_at} updated={apiData.updated_at} />}
      >
        <ButtonJsonDebug json={data} />
          
        <CapabilityFence can="update">
          <Button
            as={Link}
            to={`/administration/options/${apiData._id}/edit`}
            variant="warning"
            className="mr-2 text-white"
          >
            <FaPencilAlt/> Modifica
          </Button>
        </CapabilityFence>
      </PageHeader>
      
      <Container fluid className="content-container pt-3 pb-3">
        
        <Row>
          <Col>

            <BootstrapCard header="Informazioni di base" className="mb-4" noPadding>
              <ListGroup variant="flush">

                <ListGroupItemSplit label="Chiave">
                  <code className="text-body">{apiData.key}</code>
                </ListGroupItemSplit>

                <ListGroupItemSplit label="Valore">
                  <code className="text-body">{apiData.value}</code>
                </ListGroupItemSplit>
                
              </ListGroup>
            </BootstrapCard>         

          </Col>

        </Row>
      </Container>
    </CapabilitiesContext.Provider>
  );
}