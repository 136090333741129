import { useField } from "formik";
import React, { useRef } from "react";
import { Overlay } from "react-bootstrap";

/**
 * 
 * 
 * @param {*} props 
 * @returns 
 */
export default function TableInputText(props) {
  const [field, meta] = useField(props);
  const target = useRef(null);

  const tooltip = (
    <Overlay target={target.current} show={true} placement="top-start" transition={false}>
      {({ placement, arrowProps, show: _show, popper, ...props }) => (
        <div
          {...props}
          style={props.style}
          className="tableTooltip"
        >
          {meta.error}
        </div>
      )}
    </Overlay>
  );

  return (
    <React.Fragment>
      <input
        ref={target}
        {...field}
        {...props}
        className={"tableInput " + ( meta.touched && meta.error && 'border border-danger')}
      />

      {meta.touched && meta.error && tooltip}
    </React.Fragment>
  )
};

