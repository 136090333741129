import { useField } from 'formik';
import { BiCloud, BiCloudRain, BiCloudSnow, BiSun } from 'react-icons/bi';
import Select, { Option } from 'rc-select';


const badges = {
  clear: { icon: <BiSun color="#FC9601" size="24"/>, text: "Sereno" },
  cloudy: { icon: <BiCloud color="#b3b3b3" size="24" />, text: "Nuvoloso" },
  rain: { icon: <BiCloudRain color="#0077cf" size="24" />, text: "Pioggia" },
  snow: { icon: <BiCloudSnow color="#3BABFD" size="24" />, text: "Neve" }
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function InputWeather(props) {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  const { value } = meta;
  const { setValue } = helpers;

  /**
   * Updates the value of the input field
   * 
   * @param newValue The new value to set for the input field
   */
  const handleChange = async (newValue) => {
    setValue(newValue);
  }

  return(
    <Select
      value={value}
      filterOption={false}
      placeholder="Seleziona il meteo"
      optionLabelProp="label"
      onChange={handleChange}
    >
      {Object.keys(badges).map(key => (
        <Option key={key} value={key} label={<>{badges[key].icon} {badges[key].text}</>}>
          {badges[key].icon} {badges[key].text}
        </Option>
      ))}
    </Select>

  );
}
