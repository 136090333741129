import React from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { Button, Tabs, Tab } from "react-bootstrap";

import apiFetcher from "utils/apiFetcher";
import CreatedUpdatedString from "components/CreatedUpdatedString";
import FullPageError from "components/FullPageError";
import ButtonJsonDebug from "components/ButtonJsonDebug";
import CapabilityFence from "components/CapabilityFence";
import DeleteObjectButton from "components/DeleteObjectButton";
import { CapabilitiesContext } from 'contextes/CapabilitiesContext';
import PageHeader from "containers/PageHeader";
import BadgeSectionWorkState from "components/BadgeSectionWorkState";
import InfoShowPartialView from "./_info.show";
import EconomicsShowPartialView from "./_economics.show";

export default function ConstructionSiteShowView(props) {
  const { data, error } = useSWR(`planning/sections/${props.match.params.id}`, apiFetcher)

  if (error) return <FullPageError type="error" error={error} />
  if (!data) return <div>loading...</div>
  
  const apiData = data.results;
  return (
    <CapabilitiesContext.Provider value={{ can: data.capabilities }}>

        <PageHeader
          className="has-tabs"
          title={<>{apiData.name} <BadgeSectionWorkState value={apiData.work_state} /> </>}
          meta={<CreatedUpdatedString created={apiData.created_at} updated={apiData.updated_at} deleted={apiData.deleted_at}/>}
        >
          <ButtonJsonDebug json={data} />
               
          {!apiData.deleted_at &&
            <React.Fragment>
              <CapabilityFence can="update">
                <Button as={Link} to={`/planning/construction_sites/${apiData._id}/edit`} className="mr-2" variant="warning">
                  <BiEditAlt/> Modifica
                </Button>
              </CapabilityFence>
              
              <CapabilityFence can="destroy">
                <DeleteObjectButton
                  resource="planning/sections"
                  resourceId={apiData._id}
                  afterDeleteRedirect="/planning/construction_sites"
                />
              </CapabilityFence>
            </React.Fragment>
          }

        </PageHeader>

        <Tabs defaultActiveKey="information" className="header-tabs">
          <Tab eventKey="information" title="Informazioni">
            <InfoShowPartialView data={data} />
          </Tab>

          <Tab eventKey="budget" title="Budget / Consuntivo">
            <EconomicsShowPartialView data={data} />
          </Tab>
          
        </Tabs>
    </CapabilitiesContext.Provider>
  );
}
