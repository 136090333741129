import { permissions } from "forms/administration/user";
import React from "react";
import { Badge } from "react-bootstrap"

export default function UserPermissionsList(props) {
  
  const badges = (
    <React.Fragment>
      {props.permissions && props.permissions.map( p => (
        <Badge variant="info" className="mr-2" key={p}>
          {permissions[p] || `${p} ⚠️`}
        </Badge>
      ))}
    </React.Fragment>
  )

  return props.summary ? ( <Badge variant="info">{props.permissions?.length || 0 } Permessi</Badge> ) : badges;
}
