function Toolbar(props) {
  return (
    <div
      className={"toolbar" + (props.noTopBorder ? " no-top-border" : "")}
      style={props.alignRight && {justifyContent: "flex-end"}}
    >
      {props.children}
    </div>
  );
}

function ToolbarSpacer(props) {
  return (
    <div className="toolbar-spacer"></div>
  );
}


function ToolbarButton(props) {

  const Component = props.as || "div";

  return (
    <Component
      className={"toolbar-button " + props.className}
      to={props.to}
      onClick={props.onClick}
    >
      { props.icon && <props.icon /> }
      { props.label && <span className="button-label">{props.label}</span> }
    </Component>
  );
}

export default Toolbar;
export { ToolbarButton, ToolbarSpacer };
