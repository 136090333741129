import { Container, Row, Col } from "react-bootstrap"
import { BiGitCommit } from "react-icons/bi";

export default function Header() {
  return (
    <footer className="app-footer pt-2 pb-2">
      <Container fluid>

        <Row>
          <Col>
            <BiGitCommit size="20" style={{ marginTop: "-2px" }} color="#eb4c27"/>
            <a 
              href={"https://lab.intercom.it/umarell/commander-frontend/tree/" + process.env.REACT_APP_VERSION}
              target="_blank"
              rel="noreferrer"
              className="ml-1"
            >
              {process.env.REACT_APP_VERSION}
            </a>
          </Col>
          <Col className="text-right">
            Made with {Math.random() < 0.1 ? "🦄" : "❤️"} by &nbsp;
            <a target="_blank" rel="noreferrer nofollow" href="https://intercom.it">
              intercom s.r.l.
            </a>
          </Col>
        </Row>

        </Container>
    </footer>
  );
}
