import * as Yup from 'yup';

// Default form values
const formStructure = { 
  firstname: "",
  lastname: "",
  role: "generic",
  team: ""
}

// Form validation schema
const formSchema = Yup.object().shape({
  firstname: Yup.string()
    .required('Obbligatorio'),
  lastname: Yup.string()
    .required('Obbligatorio'),
  role: Yup.string()
    .required('Obbligatorio'),
  team: Yup.string()
    .required('Obbligatorio')
});

export { formStructure, formSchema };
