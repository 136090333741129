import { Accordion, Card } from "react-bootstrap";
import { BiError } from "react-icons/bi";

/**
 * 
 * 
 * @param props 
 * @returns 
 */
export default function ErrorDebugBlock(props) {
  console.log(props.error);

  return (
    <div className="error-debug-block">
      <Accordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <BiError style={{marginTop: "-1px"}} size="20" /> {props.error?.message}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {props.error?.info && (
                <>
                  <h6>Server message: <span className="text-muted">[Status {props.error?.status}]</span></h6>
                  <pre>{props?.error}</pre>
                  <hr />
                </>
              )}
              <pre>{props.error?.stack}</pre>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}
