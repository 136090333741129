import dayjs from "dayjs";
import React from "react";

export default function FormattedDate(props) {
  if(props.date)
    return <>{dayjs(props.date).format('DD/MM/YYYY')}</>;

  if(props.fallback)
    return <em className="text-muted">[{dayjs(props.fallback).format('DD/MM/YYYY')}]</em>;

  return <React.Fragment />
}
