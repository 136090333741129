import React from "react";

import { useFormikContext } from "formik";
import { Table, Alert } from "react-bootstrap";
import TableInputText from "components/Inputs/TableInputText";
import unitsOfMeasure from "utils/constants/unitsOfMeasure";

/**
 * Renders a sub form to edit works entries
 * This component can only be rendered inside the reports edit form
 * 
 * @param {*} props 
 * @returns 
 */
export default function CostsTableMaterialsSubForm(props) {
  const formik = useFormikContext();
  

  const elements = formik.values[props.name];

  return (
    <React.Fragment>
      <Table className="inputTable mb-0">

        <thead>
          <tr>
            <th>Materiale</th>
            <th width="25%">Costo</th>
            <th width="25%">Unità</th>
          </tr>
        </thead>

        <tbody>

        {elements.map((w, i) => (

          <tr key={i}>
            <td>
              <span className="pl-2" style={{lineHeight: "50px"}}>{elements[i].material.name}</span>
            </td>

            <td>
              <TableInputText type="number" name={props.name + '.' + i + '.cost'} min="0" />
            </td>

            <td>
              <span className="pl-2" style={{lineHeight: "50px"}}>{unitsOfMeasure[elements[i].material.unit]}</span>
            </td>
          </tr>

        ))}

        </tbody>

      </Table>

      {formik.errors.product_suppliers && typeof formik.errors.product_suppliers === 'string' && (
        <Alert variant="danger" className="m-3">{formik.errors.product_suppliers}</Alert>
      )}
    </React.Fragment>
  );
}
