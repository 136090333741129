import React from "react";
import PageHeader from "containers/PageHeader";
import DataOptionsTable from "components/data/administration/OptionsTable";

export default function OptionsIndexView() {

  return (
    <React.Fragment>

      <PageHeader title="Opzioni" />
      
      <div className="content-container">
        <DataOptionsTable />
      </div>
    </React.Fragment>
  );
}
