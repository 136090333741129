import { Card } from "react-bootstrap"

export default function BootstrapCard(props) {

  return (
    <Card className="mb-4">
      {props.header ? <Card.Header>{props.header}</Card.Header> : null}

      {props.noPadding ? <div>{props.children}</div> : <Card.Body >{props.children}</Card.Body>}

      {props.footer ? <Card.Footer>{props.footer}</Card.Footer> : null}
    </Card>
  );
}
