import * as Yup from 'yup';

// Default form values
const formStructure = { 
  key: "",
  value: "",
}


// Form validation schema
const formSchema = Yup.object().shape({
  key: Yup.string()
    .required('Obbligatorio'),
  value: Yup.string()
    .required('Obbligatorio'),
});


export { formStructure, formSchema };
