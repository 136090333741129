import { createContext } from "react";

/**
 * Holds the information about the currently logged in user
 */
const CapabilitiesContext = createContext({
  can: []
});


export { CapabilitiesContext };
