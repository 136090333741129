import React from "react";
import { BiLinkExternal } from "react-icons/bi";
import { Col, Row, ListGroup, Container } from "react-bootstrap";
import BootstrapCard from "components/BootstrapCard"; 
import ListGroupItemSplit from "components/ListGroupItemSplit";
import { CapabilitiesContext } from 'contextes/CapabilitiesContext';
import GeoJsonViewer from "components/GeoJsonViewer";
import { MapMarker } from "components/MapMarker";
import DocumentsCard from "components/data/DocumentsCard";
import FormattedDate from "components/FormattedDate";
import { LinkTeam } from "components/ResourceLinks";
import constructionSiteTypologies from "utils/constants/constructionSiteTypologies";
import roofTypologies from "utils/constants/roofTypologies";
import useDestinations from "utils/constants/useDestinations";
import communityTypologies from "utils/constants/communityTypologies";

export default function InfoShowPartialView(props) {
  const apiData = props.data.results;

  return (
    <CapabilitiesContext.Provider value={{ can: props.data.capabilities }}>

        <Container fluid className="content-container pt-3">
          <Row>
            <Col>
              
              <BootstrapCard header="Informazioni Base" noPadding>
                <ListGroup variant="flush">

                  <ListGroupItemSplit label="Codice">
                    <code className="text-body">{apiData.code}</code>
                  </ListGroupItemSplit>

                  <ListGroupItemSplit label="Posizione">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://google.com/maps/search/${apiData.address.lat},${apiData.address.lon}`}
                    >
                      {apiData.address.street}, {apiData.address.city} <BiLinkExternal color="#aaa" style={{marginTop: "-2px"}}/>
                    </a>
                  </ListGroupItemSplit>

                  <ListGroupItemSplit label="Tipologia Cliente">{constructionSiteTypologies[apiData.typology]}</ListGroupItemSplit>
                  <ListGroupItemSplit label="Tipologia Tetto">{roofTypologies[apiData.roof_typology]}</ListGroupItemSplit>
                  <ListGroupItemSplit label="Destinazione">{useDestinations[apiData.use_destination]}</ListGroupItemSplit>
                  <ListGroupItemSplit label="Tipologia">{communityTypologies[apiData.community_typology]}</ListGroupItemSplit>
                  
                  <ListGroupItemSplit label="Kilowatts">{apiData.kilowatts} KW</ListGroupItemSplit>

                  <ListGroupItemSplit label="Durata">
                    {apiData.duration && <span className="mr-2">{apiData.duration} Giorni</span>}
                    {apiData.forecasted_duration && <em className="text-muted">(Previsione {apiData.forecasted_duration} Giorni)</em>}
                  </ListGroupItemSplit>
                  
                  <ListGroupItemSplit label="Annotazioni">
                    <div className="pre-like">{apiData.annotations}</div>
                  </ListGroupItemSplit>
                </ListGroup>
              </BootstrapCard>

              <BootstrapCard header="Stato Posa Sottostruttura" noPadding>
                <table className="simple-properties-table">
                  <tbody>
                    <tr>
                      <td>Squadra</td>
                      <td colSpan={3}><LinkTeam resource={apiData.team_substructure} /></td>
                    </tr>
                    <tr>
                      <td style={{width: "25%"}}>Previsione Inizio</td>
                      <td style={{width: "25%"}}><FormattedDate date={apiData.substructure_forecasted_started_at} /></td>

                      <td style={{width: "25%"}}>Previsione Fine</td>
                      <td style={{width: "25%"}}><FormattedDate date={apiData.substructure_forecasted_ended_at} /></td>
                    </tr>
                    <tr>
                      <td>Inizio Reale</td>
                      <td><FormattedDate date={apiData.substructure_forecasted_started_at} /></td>

                      <td>Fine Reale</td>
                      <td><FormattedDate date={apiData.substructure_forecasted_ended_at} /></td>
                    </tr>
                  </tbody>
                </table>
              </BootstrapCard>

              <BootstrapCard header="Stato Posa Pannelli" noPadding>
                <table className="simple-properties-table">
                  <tbody>
                    <tr>
                      <td>Squadra</td>
                      <td colSpan={3}><LinkTeam resource={apiData.team_panels} /></td>
                    </tr>
                    <tr>
                      <td style={{width: "25%"}}>Previsione Inizio</td>
                      <td style={{width: "25%"}}><FormattedDate date={apiData.panels_forecasted_started_at} /></td>

                      <td style={{width: "25%"}}>Previsione fine</td>
                      <td style={{width: "25%"}}><FormattedDate date={apiData.panels_forecasted_ended_at} /></td>
                    </tr>
                    <tr>
                      <td>Inizio Reale</td>
                      <td><FormattedDate date={apiData.panels_forecasted_started_at} /></td>

                      <td>Fine Reale</td>
                      <td><FormattedDate date={apiData.panels_forecasted_ended_at} /></td>
                    </tr>
                  </tbody>
                </table>
              </BootstrapCard>

              <BootstrapCard header="Stato Installazione Inverter" noPadding>
                <table className="simple-properties-table">
                  <tbody>
                    <tr>
                      <td>Squadra</td>
                      <td colSpan={3}><LinkTeam resource={apiData.team_inverter} /></td>
                    </tr>
                    <tr>
                      <td style={{width: "25%"}}>Previsione Inizio</td>
                      <td style={{width: "25%"}}><FormattedDate date={apiData.inverter_forecasted_started_at} /></td>

                      <td style={{width: "25%"}}>Previsione fine</td>
                      <td style={{width: "25%"}}><FormattedDate date={apiData.inverter_forecasted_ended_at} /></td>
                    </tr>
                    <tr>
                      <td>Inizio Reale</td>
                      <td><FormattedDate date={apiData.inverter_forecasted_started_at} /></td>

                      <td>Fine Reale</td>
                      <td><FormattedDate date={apiData.inverter_forecasted_ended_at} /></td>
                    </tr>
                  </tbody>
                </table>
              </BootstrapCard>

              <BootstrapCard header="Stato Collaudo e Attivazione" noPadding>
                <table className="simple-properties-table">
                  <tbody>
                    <tr>
                      <td>Squadra</td>
                      <td colSpan={3}><LinkTeam resource={apiData.team_testing} /></td>
                    </tr>
                    <tr>
                      <td style={{width: "25%"}}>Previsione Inizio</td>
                      <td style={{width: "25%"}}><FormattedDate date={apiData.testing_forecasted_started_at} /></td>

                      <td style={{width: "25%"}}>Previsione fine</td>
                      <td style={{width: "25%"}}><FormattedDate date={apiData.testing_forecasted_ended_at} /></td>
                    </tr>
                    <tr>
                      <td>Inizio Reale</td>
                      <td><FormattedDate date={apiData.testing_forecasted_started_at} /></td>

                      <td>Fine Reale</td>
                      <td><FormattedDate date={apiData.testing_forecasted_ended_at} /></td>
                    </tr>
                  </tbody>
                </table>
              </BootstrapCard>
              
            </Col>

            <Col>
              <BootstrapCard noPadding>
                <GeoJsonViewer
                  center={{ lat: apiData.address.lat, lng: apiData.address.lon}}
                  data={apiData.map_data}
                  height="342px"
                >
                  <MapMarker lat={apiData.address.lat} lng={apiData.address.lon}/>
                </GeoJsonViewer>
              </BootstrapCard>

              <DocumentsCard data={apiData} />

            </Col>
          </Row>
        </Container>

    </CapabilitiesContext.Provider>
  );
}
