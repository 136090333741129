import dayjs from 'dayjs'

export default function CreatedUpdatedString(props) {

  return (
    <small className="text-muted">
      Creato {dayjs(props.created).format('DD/MM/YYYY HH:mm')} &middot;
      Aggiornato {dayjs(props.updated).format('DD/MM/YYYY HH:mm')}

      {props.deleted && <> &middot; Eliminato {dayjs(props.deleted_at).format('DD/MM/YYYY HH:mm')}</>}
    </small>
  );
}
