import Table from 'rc-table';
import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import useSWR from 'swr';
import { useContext, useState } from 'react';
import { BiFilterAlt, BiSortDown, BiSortUp, BiSpreadsheet } from 'react-icons/bi';
import apiFetcher from 'utils/apiFetcher';
import ErrorDebugBlock from 'components/ErrorDebugBlock';
import PaginationGeneric from './PaginationGeneric';
import PlanningIndexContext from 'contextes/PlanningIndexContext';
import { useHistory } from 'react-router';
import BlockEmpty from 'components/BlockEmpty';
import FullPageLoading from 'components/FullPageLoading';

/**
 * 
 * 
 * @param props 
 * 
 * @returns 
 */
export default function TableGeneric(props) {
  const planningCtx  = useContext(PlanningIndexContext);
  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState(1);
  const [sorting, setSorting] = useState(props.defaultSort);
  const [filters, setFilters] = useState(props.filtersDefaults);

  

  /**
   * Compose the url for a resource with pagination, filters and sorting
   * 
   * @returns {string} The api url to use with SWR
   */
  const composeApiUrl = () => {
    let apiUrl = `${props.resource}?page=${selectedPage}`;

    if(props.defaultSort)
      apiUrl += `&sort_field=${sorting[0]},${sorting[1]}`;

    if(filters || props.forcedFilters) {
      let validFilters = {}

      if(filters)
        validFilters  = {...filters}

      if(props.forcedFilters)
        validFilters  = {...validFilters, ...props.forcedFilters}
      
      apiUrl += `&${new URLSearchParams(validFilters).toString()}`;
    }

    return apiUrl;
  }

  const { data, error } = useSWR(composeApiUrl(), apiFetcher);

  /**
   * Updates the filters while resetting the active page 
   * 
   * @param {*} filters An object containing the filters to apply
   */
  const handleFiltersChange = (filters) => {
    setSelectedPage(1);
    setFilters(filters);
  }

  /**
   * Computes the properties for a header cell.
   * This function sets the on click even to update the sorting.
   * 
   * @param {*} field The name of the field to sort on
   * @param {*} direction The direction of the sorting
   * 
   * @returns {object} An object containing the properties to apply to the column header
   */
   const onHeaderCell = (field) => ({
    className: "cell-sortable",
    onClick: () => {
      const direction = sorting[1] === 'asc' ? 'desc' : 'asc';

      setSorting([String(field), direction]);
      setSelectedPage(1);
    },
  })

  /**
   * Creates a title with the appropriate sorting icons
   * 
   * @param title The title of the column
   * @param field The name of the field
   * 
   * @returns {ReactNode} The element to use as a column header
   */
  const HeaderCellWithSorting = (title, field) => {
    return (
      <>
        {title}
        { field === sorting[0] && 
            (sorting[1] === 'asc' ?
            <BiSortUp className="float-right mt-1" /> :
            <BiSortDown className="float-right mt-1" />
          )
        }
      </>
    )
  }

  /**
   * Computes the properties for a table row
   * 
   * @param {object} record The object containing the values for the row
   * 
   * @returns {object} An object containing the properties to apply the row
   */
  const onRowHandler = (record) => {
    return {
      className: planningCtx.document && record._id === planningCtx.document.active ? 'bg-light' : '',
      onClick: () => {
        if(planningCtx.document)
          planningCtx.document.set(record._id);
      },
      onDoubleClick: () => {
        if(!props.rowLinkLocation)
          return;

        history.push(`/${props.rowLinkLocation}/${record._id}`);
      }
    }
  }

  const columns = props.columns.map((col) => {
    return {
      ...col,
      title: col.disableSort ? col.title : HeaderCellWithSorting(col.title, col.dataIndex),
      onHeaderCell: () => { return col.disableSort ? {} : onHeaderCell(col.dataIndex) }
    }
  });

  if (error) return <ErrorDebugBlock title={error.message} />;
  if (!data) return <FullPageLoading />;

  const popover = props.filtersForm && (
    <Popover id="popover-basic" style={{maxWidth: "auto"}}>
      <Popover.Title><BiFilterAlt style={{marginTop: "-3px"}} /> Filtri</Popover.Title>
      <Popover.Content className="p-2" style={{minWidth: "350px"}}>
          <props.filtersForm onFilterChange={handleFiltersChange} activeFilters={filters}/>
      </Popover.Content>
    </Popover>
  );

  return(
    <div className="rc-table-wrapper">

      <div className="wrapper-table">
        <Table
          columns={columns}
          data={data.results}
          rowKey={(record) => record._id}
          onRow={onRowHandler}
          className={props.rowLinkLocation ? "rc-table-clickable" : ""}
          emptyText={<BlockEmpty message="Nessun elemento" />}
        />
      </div>

      <div className="wrapper-meta">
        <Row>
          <Col className="meta-text">
            {data.pagination.count} Elementi

            {props.filtersForm && (
              <OverlayTrigger trigger="click" placement="top-start" overlay={popover} transition={false}>
                <Button variant="custom" className="ml-3">
                  <BiFilterAlt style={{marginTop: "-3px"}} /> Filtri
                </Button>
              </OverlayTrigger>
            )}


            {props.allowXlsxDownload && (
            <Button
              as="a"
              target="_blank"
              rel="noopener"
              variant="custom"
              href={"/api/v1/" + composeApiUrl().replace(`${props.resource}?page=${selectedPage}`, `${props.resource}.xlsx?`)}
            >
              <BiSpreadsheet style={{marginTop: "-3px"}} /> Esporta .xlsx
            </Button>
            )}
            
          </Col>

          <Col>
            <PaginationGeneric
              pagination={data.pagination}
              selectedPage={selectedPage}
              onPageChange={setSelectedPage}
            />
          </Col>
        </Row>
      </div>

    </div>

  );
}

//<TableMetaSearch />