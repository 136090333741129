import { Badge } from "react-bootstrap";

/**
 * 
 * @param {*} enum_value 
 */
function stateInfoBySlug(enum_value) {
  const enum_list = {
    preparation: { variant: "secondary", label: "Non Pianificato" },
    planned: { variant: "dark", label: "Pianificato" },
    in_progress: { variant: "warning", label: "In Corso" },
    overdue: { variant: "danger", label: "In Ritardo" },
    dig_complete: { variant: "success-outline", label: "Scavo Completo" },
    waiting_tasks: { variant: "warning", label: "Attesa Task" },
    tasks_complete: { variant: "success-outline", label: "Task Completi" },
    complete: { variant: "success", label: "Completato" }
  };

  if(enum_list[enum_value])
    return enum_list[enum_value];

  return { variant: "secondary", label: "Sconosciuto" }
}

export default function BadgeSectionWorkState(props) {

  const state = stateInfoBySlug(props.value);

  return (
    <Badge
      variant={state.variant}
      className={props.className}
    >
      {state.label}
    </Badge>
  );
}

