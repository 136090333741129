import useSWR from 'swr';
import apiFetcher from 'utils/apiFetcher';
import useApiMutator from 'utils/apiMutator';
import { useHistory } from "react-router";

import { formStructure, formSchema } from "forms/administration/costs_table";

import { Row, Col, Container, Alert } from "react-bootstrap";
import FormWrapper from 'components/Forms/FormWrapper';
import FullPageError from 'components/FullPageError';
import BootstrapCard from 'components/BootstrapCard';
import RowInput from 'containers/RowInput';
import CostsTableWorkersSubForm from 'components/Forms/CostsTableWorkersSubForm';
import CostsTableMaterialsSubForm from 'components/Forms/CostsTableMaterialsSubForm';
import { cloneDeep } from 'lodash-es';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function CostsTablesEditView(props) {
  const history = useHistory();
  const id = props.match.params.id;

  const { data, error } = useSWR(id ? `core/costs_tables/${id}` : null, apiFetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
  const [mutate, mutationError]= useApiMutator("core/costs_tables");

  /**
   * 
   * @param {*} values 
   */
  const handleSubmit = async (values) => {
    const postData = cloneDeep(values);

    postData.costs_table_workers = postData.costs_table_workers.map(u => { return {...u, worker: u.worker._id} });
    postData.costs_table_materials = postData.costs_table_materials.map(u => { return {...u, material: u.material._id} });

    const data = await mutate({ costs_table: postData });

    if(data)
      history.replace(`/administration/costs_tables/${data.results._id}`);
  }

  // If this is a new item, apply the default values
  let initialValues = formStructure;

  if(data?.results) {
    initialValues = data?.results;
  }

  if (error) return <FullPageError type="error" error={error} />
  if (id && !data) return <div>loading...</div>

  return (
    <Container fluid className="content-container pt-3 pb-3">

      <h3 className="mb-3">{data ? "Modifica Tabella Costi" : "Aggiungi Tabella Costi" }</h3>

      {mutationError && <Alert variant="danger">{mutationError}</Alert>}

      <FormWrapper
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
      >

        <Row>
          <Col md="6">

            <BootstrapCard header="Incidenze">

              <RowInput label="Incidenze Generali" type="number" min="0" name="incidences_general" postLabel="%"/>
              <RowInput label="Incidenze di gestione" type="number" min="0" name="incidences_management" postLabel="%"/>
              
            </BootstrapCard>

          </Col>

        </Row>

        <Row>
          <Col md="6">
            <BootstrapCard header="Personale" noPadding>
              <CostsTableWorkersSubForm name="costs_table_workers" />
            </BootstrapCard>
          </Col>

          <Col md="6">
            <BootstrapCard header="Materiali" noPadding>
              <CostsTableMaterialsSubForm name="costs_table_materials" />
            </BootstrapCard>
          </Col>
        </Row>

      </FormWrapper>
    </Container>
  );
}
