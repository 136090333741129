import Header from "../../containers/Header";
import Footer from "../../containers/Footer";
//import Breadcrumbs from '../../components/Breadcrumbs';

export default function AppLayout(props) {
  return (
    <div className="app">
      <Header />

      

      <div className="app-content">
          {props.children}
      </div>

      <Footer />
    </div>
  );
}

// <Breadcrumbs />
//<Container fluid>
        //  {props.children}
    //    </Container>
