
function  TableRow(props) {
  return (
      <tr>
        <th>{props.label}</th>
        <td>{props.children}</td>
      </tr>
  );
}

function  SimplePropertiesTable(props) {

  const classNames = ["simple-properties-table"];

  if(props.borderTop)
    classNames.push("table-border-top");

  if(props.borderBottom)
    classNames.push("table-border-bottom");

  return (
    <table className={classNames.join(" ")}>
      <tbody>
        {props.children}
      </tbody>
    </table>
  );
}

export default SimplePropertiesTable;
export { SimplePropertiesTable, TableRow }
