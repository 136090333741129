
/**
 * Sends requests to the backend authentication endpoint
 * 
 * @param {*} method The http method to use either GET, POST, DELETE. Defaults to GET.
 * @param {*} values An object containing the credentials to pass to the backend
 * 
 * @throws Will rise an exception if unable to fetch or if the server returns anything other than 200
 * @returns {Object}
 */
const precomposedFetch = async (method, values) => {
  const options = {
    credentials: "include"
  }

  if(method)
    options.method = method;

  if(method === "POST") {
    options.headers = { "Content-type": "application/json; charset=UTF-8" }
    options.body = JSON.stringify(values)
  }

  const res = await fetch(process.env.REACT_APP_BACKEND_URL + "authentication", options);

  if (!res.ok) {
    const error = (await res.json()).error.message;

    throw new Error(error)
  }

  if(method !== "DELETE")
    return (await res.json()).results;
  else
    return true;
}

/**
 * Sends an authentication request to the backend
 * 
 * @param {Object} values An object containing the credentials to pass to the backend
 * 
 * @returns {Object}
 */
 const authLogin = async (values) => {
  return await precomposedFetch("POST", values);
}

/**
 * Loads the information about the currently logged in user
 * 
 * @returns {Object}
 */
const authFetch = async () => {
  return await precomposedFetch();
}

/**
 * Terminates the current session
 * 
 * @returns {boolean}
 */
const authLogout = async () => {
  return await precomposedFetch("DELETE");
}

export { authLogin, authFetch, authLogout }
