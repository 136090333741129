import { Button, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import React from 'react';
import PermissionFence from 'components/PermissionFence';
import PageHeader from 'containers/PageHeader';
import DataWorkersTable from "components/data/administration/WorkersTable";

export default function WorkersIndexView() {

  return (
    <React.Fragment>
      <PageHeader title="Operai">
        <PermissionFence mustHave={["reports_administrator"]}>
          <Button as={Link} to="/administration/workers/new" variant="success"><FaPlus/> Crea</Button>
        </PermissionFence>
      </PageHeader>

      <div className="content-container">
        <DataWorkersTable />
      </div>
    </React.Fragment>
  );
}
