import { Nav, Navbar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ProfileDropdown from 'components/Header/ProfileDropdown';

import HeaderMenu from 'components/Header/HeaderMenu';
import logo from 'assets/images/youpower-logo-white.png';

/**
 * Renders the application global header
 * 
 * @returns {React.FunctionComponent}
 */
export default function Header() {

  return (
    <header className="app-header">
      <Navbar variant="dark" className="bg-primary" expand="lg">

        <Navbar.Brand href="#home" as={Link} to="/">
        <img
          src={logo}
          height="16"
          className="d-inline-block align-top"
          alt="YouPower - Solaris"
          style={{ marginTop: '7px' }}
        />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          
          <HeaderMenu />

          <Nav className="ml-2">

            <ProfileDropdown />

          </Nav>

        </Navbar.Collapse>
      </Navbar>

      {process.env.REACT_APP_STAGING && <div className="header-staging"></div>}
    </header>
  );
}

/*

*/