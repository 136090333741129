import Table from "rc-table";
import BlockEmpty from "components/BlockEmpty";
import unitsOfMeasure from "utils/constants/unitsOfMeasure";
import React, { useContext } from "react";
import AuthContext from "contextes/AuthContext";
import { intersection } from "lodash-es";
import { Col, Row } from "react-bootstrap";

export default function  ConstructionSiteMaterialsTable(props) {
  const  { user } = useContext(AuthContext);

  const columns = [
    {
      title: "Nome",
      dataIndex:"material",
      render: (v) => v.name
    },
    {
      title: "Quantità",
      dataIndex:"quantity",
      render: (v, r) => `${v} ${unitsOfMeasure[r.material.unit]}`,
      width: "25%"
    }
  ]

  if(intersection(["economics", "administration"], user.permissions).length !== 0)
    columns.push({
      title: "Costo",
      dataIndex:"cost",
      render: v => v && `${v} CHF`,
      width: "25%"
    });

  /**
   * Sorts the data array by material name
   */
  const sortByName = (data) => {
    data.sort((a, b) => (a.material.name > b.material.name) ? 1 : -1);

    return data;
  }

  return (
    <React.Fragment>
      <Table
        columns={columns}
        data={sortByName(props.data)}
        emptyText={<BlockEmpty message="Nessun Materiale" />}
        rowKey={record => record._id}
      />

      {props.total ? (
        <Row className="border-top m-0">
          <Col className="text-right p-2 border-right font-weight-bold">Totale</Col>
          <Col md={3} className="p-2">{props.total} CHF</Col>
        </Row>
      ) : <React.Fragment />}
    </React.Fragment>
  );
}
