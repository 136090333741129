import { BiCube } from "react-icons/bi";

export default function BlockEmpty(props) {

  return (
    <div className={"text-center " + (props.compact ? "p-3" : "p-4")}>
      {props.icon || <BiCube size={props.compact ? 30 : 60} color="#eee"/>}
      <h6 className="m-0" style={{color: "#ddd"}}>{props.message || "Nessun elemento"}</h6>
    </div>
  );
}
