import React from 'react';
import useSWR from 'swr'
import { Row, Col, Button, ListGroup, Container, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import apiFetcher from 'utils/apiFetcher';
import FullPageError from 'components/FullPageError';
import ButtonJsonDebug from 'components/ButtonJsonDebug';
import CreatedUpdatedString from 'components/CreatedUpdatedString';
import ListGroupItemSplit from 'components/ListGroupItemSplit';
import BootstrapCard from 'components/BootstrapCard';
import PageHeader from 'containers/PageHeader';
import dayjs from 'dayjs';
import DataCostsTablesWorkersTable from 'components/data/administration/costs_tables/CostsTablesWorkersTable';
import DataCostsTablesMaterialsTable from 'components/data/administration/costs_tables/CostsTablesMaterialsTable';
import { CapabilitiesContext } from 'contextes/CapabilitiesContext';
import CapabilityFence from 'components/CapabilityFence';
import DeleteObjectButton from 'components/DeleteObjectButton';
import PermissionFence from 'components/PermissionFence';


export default function CostsTablesShowView(props) {
  const { data, error } = useSWR(`core/costs_tables/${props.match.params.id}`, apiFetcher)
  
  if (error) return <FullPageError type="error" error={error} />
  if (!data) return <div>loading...</div>

  const results = data.results;

  return (
    <CapabilitiesContext.Provider value={{ can: data.capabilities }}>

      <PageHeader
        title={
          <>
            {dayjs(results.created_at).format('DD MMMM YYYY')} <span className="text-muted">Rev.{dayjs(results.created_at).format('HHmm')}</span>
            {results.current ? (
              <Badge variant="success" className="ml-1">Attiva</Badge>
            ):(
              <Badge variant="danger" className="ml-1">Obsoleta</Badge>
            )}
          </>
        }
        meta={<CreatedUpdatedString created={results.created_at} updated={results.updated_at} />}
      >
        <ButtonJsonDebug json={data} />
                     
        <CapabilityFence can="update">
          <Button
            as={Link}
            to={`/administration/costs_tables/${results._id}/edit`}
            variant="warning"
            className="mr-2 text-white"
          >
            <FaPencilAlt/> Modifica
          </Button>          
        </CapabilityFence>

        <CapabilityFence can="destroy">
            <DeleteObjectButton
              resource="core/costs_tables"
              resourceId={results._id}
              afterDeleteRedirect="/administration/costs_tables"
            />
        </CapabilityFence>
        
      </PageHeader>
      
      <Container fluid className="content-container pt-3">
        <Row>
          <Col md="6">

            <PermissionFence mustHave={["economics"]}>
              <BootstrapCard header="Incidenze" className="mb-4" noPadding>
                <ListGroup variant="flush">
                  <ListGroupItemSplit label="Incidenze Generali">{results.incidences_general}%</ListGroupItemSplit>
                  <ListGroupItemSplit label="Incidenze di gestione">{results.incidences_management}%</ListGroupItemSplit>
                </ListGroup>
              </BootstrapCard>
            </PermissionFence>


          </Col>
          <Col md="6"></Col>
        </Row>

        <Row>
          <Col md="6">
            <BootstrapCard header="Personale" noPadding>
              <DataCostsTablesWorkersTable data={results.costs_table_workers} />
            </BootstrapCard>
          </Col>
          <Col md="6">
            <BootstrapCard header="Materiali" noPadding>
              <DataCostsTablesMaterialsTable data={results.costs_table_materials} />
            </BootstrapCard>
          </Col>
        </Row>
      </Container>
    </CapabilitiesContext.Provider>
  );
}