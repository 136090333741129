import * as Yup from 'yup';

// Default form values
const formStructure = { 
  name: "",
  foreman: ""
}

// Form validation schema
const formSchema = Yup.object().shape({
  name: Yup.string()
    .required('Obbligatorio'),
  foreman: Yup.string()
    .required('Specifica il capo cantiere')
});

export { formStructure, formSchema };
