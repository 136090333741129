import { Form } from "react-bootstrap";
import FormWrapper from "./FormWrapper";
import InputSelect from "../Inputs/InputSelect";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import { BiFilterAlt } from "react-icons/bi";

export default function WorkersFilterForm(props) {

  /**
   * 
   * @param {Object} values 
   */
  const handleFilter = (values) => {
    // Removes all keys with falsy values
    values = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== ""));

    props.onChange(values);
  }
  
  const popover =  (
    <Popover id="popover-basic">
      <Popover.Title as="h3"><BiFilterAlt style={{marginTop: "-2px"}}/> Filtri</Popover.Title>
      <Popover.Content>
        
        <FormWrapper
          initialValues={props.activeFilters}
          validationSchema={props.validationSchema}
          onSubmit={handleFilter}
          submitLabel="Filtra"
        >

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Ruolo</Form.Label>
            
            <InputSelect name="role">
              <option value=""></option>
              <option value="generic">Generico</option>
              <option value="foreman">Capo Cantiere</option>
              <option value="electrician">Elettricista</option>
              <option value="external">Esterno</option>
            </InputSelect>
          </Form.Group>

        </FormWrapper>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="top-start" overlay={popover} transition={false}>
      <Button variant="light" className="ml-3">
        <BiFilterAlt style={{marginTop: "-2px"}}/> Filtri
      </Button>
    </OverlayTrigger>
  )

}

