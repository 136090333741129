const documentsTypologies = { 
  "planimetric_scheme": "Schema Planimetrico",
  "single_feed_scheme": "Schema Unifilare",
  "materials_technical_scheme": "Schema tecniche materiali",
  "bill_of_materials": "Distinta Materiali",
  "rta_plant_grd": "Rta impianto GRD",
  "grd_system_notice": "Avviso impianto GRD",
  "cantonal_incentives_announcement": "Annuncio Incentivi (cantonali)",
  "city_notice": "Notifica Comune",
  "pronovo_incentives_application": "Domanda Incentivi Pronovo",
  "city_authorization": "Domanda Comune (eventuale)",
  "construction_site_safety": "Sicurezza cantiere (file 44095.i SUVA)",
  "ra_si_dc_ac": "Ra.Si-DC-AC",
  "notice_of_plant_terminated_in_grd": "Avviso impianto terminato a GRD",
  "go_grd_certificate": "Certificato GO GRD >100kwp",
  "form_for_commissioning_res_ti": "Formulario messa in funzione FER (TI)",
  "fire_test_certificate": "Certificato di collaudo antincendio",
  "practical_dispatch_pronovo": "Invio Pratica Pronovo",
  "city_notice_of_completion_of_works": "Avviso fine lavori al Comune",
  "quality_certification_external_body": "Certificazione qualità ente esterno (>100kwp)"
};

export default documentsTypologies;