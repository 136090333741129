import React from 'react';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import PageHeader from "containers/PageHeader";
import DataTeamsTable from "components/data/administration/teams/TeamsTable";

export default function TeamsIndexView() {

  return (
    <React.Fragment>

      <PageHeader title="Società">
        <Button as={Link} to="/administration/teams/new" variant="success"><FaPlus/> Crea</Button>
      </PageHeader>
      
      <div className="content-container">
        <DataTeamsTable />
      </div>

    </React.Fragment>
  );
}
