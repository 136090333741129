import * as Yup from 'yup';

// Default form values
const formStructure = {
  worker: "",
  quantity: 1,
}

// Form validation schema
const formSchema = Yup.object().shape({
  worker: Yup.string()
    .required('Obbligatorio'),
  quantity: Yup.number()
    .min(0)
    .required('Obbligatorio'),
});

export { formStructure, formSchema };
