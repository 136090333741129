import { Col, Row } from 'react-bootstrap';

/**
 * Renders
 * 
 * @returns {React.FunctionComponent}
 */
export default function PageHeader(props) {

  return (
    <header className={`p-3 page-header ${props.className}`} style={props.style}>
      <Row>
        <Col>
          <h2 className="mb-0">{props.title}</h2>
          {props.meta}
        </Col>
        
        { props.children && (
          <Col md="auto" className="text-right pt-2">
            {props.children}
          </Col>
        )}

      </Row>
    </header>
  );
}
