import useSWR, { useSWRConfig } from 'swr';
import apiFetcher from 'utils/apiFetcher';
import useApiMutator from 'utils/apiMutator';
import { useHistory } from "react-router";

import { formStructure, formSchema } from "forms/administration/team";

import { Row, Col, Container, Alert } from "react-bootstrap";
import FormWrapper from 'components/Forms/FormWrapper';
import FullPageError from 'components/FullPageError';
import BootstrapCard from 'components/BootstrapCard';
import RowInput from 'containers/RowInput';
import InputWorkerSelect from 'components/Inputs/InputWorkerSelect';
import { cloneDeep, identity, pickBy } from 'lodash-es';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function TeamsEditView(props) {
  const history = useHistory();
  const { mutate } = useSWRConfig();
  const id = props.match.params.id;

  const { data, error } = useSWR(id ? `core/teams/${id}` : null, apiFetcher);
  const [mutateApi, mutationError]= useApiMutator("core/teams");

  /**
   * 
   * @param {*} values 
   */
  const handleSubmit = async (values) => {
    const data = await mutateApi({ team: values });

    // Triggers a data revalidation
    if(id)
      mutate(`core/teams/${id}`);

    if(data)
      history.replace(`/administration/teams/${data.results._id}`);
  }


  let initialValues = cloneDeep(formStructure);

  if(data?.results) {
    initialValues = {...initialValues, ...pickBy(data.results, identity)}

    initialValues.foreman = initialValues.foreman?._id;
    delete initialValues.workers;
  }
  

  if (error) return <FullPageError type="error" error={error} />
  if (id && !data) return <div>loading...</div>

  return (
    <Container fluid className="content-container pt-3 pb-3">
      <Row className="justify-content-md-center">
        <Col md="6">
          <h3 className="mb-3">
            {data ? `Modifica ${data.results.name}` : "Aggiungi Squadra" }
          </h3>

          {mutationError && <Alert variant="danger">{mutationError}</Alert>}

          <FormWrapper
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
  
            <BootstrapCard header="Informazioni di base">

                <RowInput label="Nome" type="text" name="name" />

                <RowInput label="Capo Cantiere" name="foreman">
                  <InputWorkerSelect name="foreman" filterRole="foreman"/>
                </RowInput>               

            </BootstrapCard>

          </FormWrapper>
        </Col>
      </Row>
    </Container>
  );
}
