import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, ListGroup, Container, Alert } from "react-bootstrap";

import BootstrapCard from "components/BootstrapCard"; 
import ListGroupItemSplit from "components/ListGroupItemSplit";
import { CapabilitiesContext } from 'contextes/CapabilitiesContext';
import DataTeamWorkersTable from "components/data/administration/teams/TeamWorkersTable";
import Identicon from "components/Identicon";

export default function InfoShowPartialView(props) {
  const apiData = props.data.results;

  return (
    <CapabilitiesContext.Provider value={{ can: props.data.capabilities }}>

      <Container fluid className="content-container pb-3">

      {apiData.deleted_at && (
        <Alert variant="warning" className="mt-3">Questa squadra è stata eliminata</Alert>
      )}

      <Row className="mt-3">
        <Col>

          <BootstrapCard header="Informazioni di base" noPadding>
            <ListGroup variant="flush">

              <ListGroupItemSplit label="Capo cantiere">
                <Link to={`/administration/workers/${apiData.foreman._id}`}>{apiData.foreman.name}</Link>
              </ListGroupItemSplit>
              
            </ListGroup>
          </BootstrapCard>

          <BootstrapCard header="Operai nella squadra" noPadding>
            <DataTeamWorkersTable data={apiData.workers} />
          </BootstrapCard>

        </Col>

        <Col md="4">

          <BootstrapCard header="Identicon">
            <Identicon md5={apiData._id} size="500"/>
          </BootstrapCard>

        </Col>
      </Row>
      </Container>

    </CapabilitiesContext.Provider>
  );
}
