import { Container} from 'react-bootstrap';
import { BiError } from "react-icons/bi";



export default function FullPageError(props) {
  return (
    <Container fluid className="text-center mt-5 mb-5">

      <BiError size="120" color="#ccc" />
      
      <h3>{props.title || props.error.message}</h3>

      <p>{props.message || props.error?.info.error.message}</p>

    </Container>
  );
}
