import useSWR from 'swr';
import { useHistory } from "react-router";

import apiFetcher from 'utils/apiFetcher';
import useApiMutator from 'utils/apiMutator';

import { formStructure, formSchema } from "forms/planning/constructionSite";

import { Row, Col, Container, Alert } from "react-bootstrap";
import FormWrapper from 'components/Forms/FormWrapper';
import FullPageError from 'components/FullPageError';
import BootstrapCard from 'components/BootstrapCard';
import RowInput from 'containers/RowInput';
import { cloneDeep, identity, pickBy } from 'lodash';
import InputCity from 'components/Inputs/InputCity';
import MaterialsCostsSubform from 'components/Forms/MaterialsCostsSubform';
import WorkersCostsSubform from 'components/Forms/WorkersCostsSubform';
import InputTeamSelect from 'components/Inputs/InputTeamSelect';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function ConstructionSiteEditView(props) {
  const history = useHistory();
  const id = props.match.params.id;

  const { data, error } = useSWR(id ? `planning/sections/${id}` : null, apiFetcher);
  const [mutate, mutationError, isSaving]= useApiMutator("planning/sections");

  /**
   * 
   * @param {*} values 
   */
  const handleSubmit = async (values) => {
    const mutationValues = cloneDeep(values);

    mutationValues.permit = mutationValues.permit?._id || false;
    mutationValues.surety = mutationValues.surety?._id || false;

    const data = await mutate({ section: mutationValues });

    if(data)
      history.replace(`/planning/construction_sites/${data.results._id}`);
  }

  if (error) return <FullPageError type="error" error={error} />
  if (id && !data) return <div>loading...</div>

  let initialValues = cloneDeep(formStructure);

  if(data?.results) {
    initialValues = {...initialValues, ...pickBy(data.results, identity)}

    initialValues.forecasted_starts_after = initialValues.forecasted_starts_after?._id;

    initialValues.team_substructure = initialValues.team_substructure?._id;
    initialValues.team_panels = initialValues.team_panels?._id;
    initialValues.team_inverter = initialValues.team_inverter?._id;
    initialValues.team_testing = initialValues.team_testing?._id;

    initialValues.costs_table_budget = initialValues.costs.budget.table?._id

    initialValues.section_workers = initialValues.costs.budget.workers.map(e => {
      return { worker: e.worker._id, quantity: e.quantity }
    });

    initialValues.section_materials = initialValues.costs.budget.materials.map(e => {
      return { material: e.material._id, quantity: e.quantity }
    });

    delete initialValues.costs
    delete initialValues.attachments
    delete initialValues.ordered_execution_plan
    delete initialValues.map_data
  }

  return (
    <Container fluid className="content-container pt-3 pb-3">
      <h3 className="mb-3">
        {data ? `Modifica ${data.results.address.street}, ${data.results.address.city}` : "Aggiungi Cantiere" }
      </h3>

      {mutationError && <Alert variant="danger">{mutationError}</Alert>}

      <FormWrapper
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
        isLoading={isSaving}
      >
        <Row>
          <Col>

            <BootstrapCard header="Informazioni di base">

              <RowInput label="Nome" type="text" name="name" />

              <hr />

              <RowInput label="Città" name="address.city">
                <InputCity name="address.city" />
              </RowInput>

              <RowInput label="Indirizzo" type="text" name="address.street" />

              <hr/>

              <RowInput label="Tipologia Cliente" type="select" name="typology">
                <option value=""></option>
                <option value="production">Produzione</option>
                <option value="sell">Vendita</option>
              </RowInput>

              <RowInput label="Tipologia tetto" type="select" name="roof_typology">
                <option value=""></option>
                <option value="flat">Piano</option>
                <option value="corrugated">Grecato</option>
                <option value="shingles">Tegole</option>
                <option value="other">Altro</option>
              </RowInput>

              <RowInput label="Destinazione" type="select" name="use_destination">
                <option value=""></option>
                <option value="residential">Residenziale</option>
                <option value="industrial">Industriale</option>
                <option value="condominium">Condominio</option>
              </RowInput>

              <RowInput label="Tipologia" type="select" name="community_typology">
                <option value=""></option>
                <option value="rcp">Comunità di consumo</option>
                <option value="ppa">Contatore singolo</option>
                <option value="traditional">Tradizionale</option>
              </RowInput>
              
              <RowInput label="Kilowatt" type="number" min="0" name="kilowatts" />

              <hr/>

              <RowInput label="Annotazioni" type="text" as="textarea" rows={5} name="annotations" style={{marginBottom: "0"}}/>           

            </BootstrapCard>


            <BootstrapCard header="Operai" noPadding>
              <WorkersCostsSubform name="section_workers"/>
            </BootstrapCard>

            <BootstrapCard header="Materiali" noPadding>
              <MaterialsCostsSubform name="section_materials" />
            </BootstrapCard>

          </Col>
          
          <Col>
            <BootstrapCard header="Pianificazione">

              <h6 className='bg-light p-2'>Posa Sottostruttura</h6>

              <RowInput label="Squadra" name="team">
                <InputTeamSelect name="team_substructure"/>
              </RowInput>

              <RowInput label="Previsione inizio" type="date" name="substructure_forecasted_started_at" />
              <RowInput label="Previsione fine" type="date" name="substructure_forecasted_ended_at" />
 
              <h6 className='bg-light p-2'>Posa Pannelli</h6>

              <RowInput label="Squadra" name="team">
                <InputTeamSelect name="team_panels"/>
              </RowInput>

              <RowInput label="Previsione inizio" type="date" name="panels_forecasted_started_at" />
              <RowInput label="Previsione fine" type="date" name="panels_forecasted_ended_at" />
              
              <h6 className='bg-light p-2'>Installazione Inverter</h6>

              <RowInput label="Squadra" name="team">
                <InputTeamSelect name="team_inverter"/>
              </RowInput>

              <RowInput label="Previsione inizio" type="date" name="inverter_forecasted_started_at" />
              <RowInput label="Previsione fine" type="date" name="inverter_forecasted_ended_at" />
 
              <h6 className='bg-light p-2'>Collaudo e Attivazione</h6>


              <RowInput label="Squadra" name="team">
                <InputTeamSelect name="team_testing"/>
              </RowInput>

              <RowInput label="Previsione inizio" type="date" name="testing_forecasted_started_at" />
              <RowInput label="Previsione fine" type="date" name="testing_forecasted_ended_at" />

            </BootstrapCard>

          </Col>
        </Row>

      </FormWrapper>
    </Container>
  );
}