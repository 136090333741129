import TableGeneric from "components/data/TableGeneric";

const columns = [
  {
    title: 'ID',
    dataIndex: '_id',
    disableSort: true,
    width: '20%',
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    disableSort: true
  }
]

export default function  MaterialsTable(props) {
  
  return (
    <TableGeneric 
      columns={columns}
      resource="core/materials"
    />
  );
}
