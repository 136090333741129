import TableGeneric from "components/data/TableGeneric";
import Identicon from "components/Identicon";
import { Link } from "react-router-dom";


const columns = [
  {
    title: "Name",
    dataIndex: 'name',
    render: (v, r) => (
      <Link to={`/administration/teams/${r._id}`}>
        <Identicon
              md5={r._id}
              size="18"
              className="rounded border mr-1"
              style={{marginTop: "-2px"}}
            /> 
        {v}
      </Link>
    ),
  },
  {
    title: "Capo Cantiere",
    dataIndex: 'foreman',
    disableSort: true,
    render: v => <Link to={`/administration/workers/${v._id}`}>{v.name}</Link>,
  },
  {
    title: "Lavoratori",
    disableSort: true,
    dataIndex: 'workers_count',
  },
];

/**
 * 
 * 
 * @param props 
 * @returns 
 */
export default function DataTeamsTable(props) {

  return (
    <TableGeneric 
      columns={columns}
      resource="core/teams"
      rowLinkLocation="administration/teams"
      defaultSort={["name", "asc"]}
    />
  );
}
