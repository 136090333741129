import * as Yup from 'yup';

// Default form values
const formStructure = {
  material: "",
  quantity: 1,
  unit: false
}

// Form validation schema
const formSchema = Yup.object().shape({
  material: Yup.string()
    .required('Obbligatorio'),
  quantity: Yup.number()
    .min(0)
    .required('Obbligatorio'),
});

export { formStructure, formSchema };
