import { intersection } from "lodash-es";
import React, { useContext, useState } from "react";
import { Modal,Button } from "react-bootstrap";
import { FaBug } from "react-icons/fa";
import ReactJson from "react-json-view";
import AuthContext from "../contextes/AuthContext";


/**
 * Display a button to open a modal with the given JSON object pretty printere
 * 
 * @param {*} props 
 * @returns 
 */
export default function ButtonJsonDebug(props) {
  const  { user } = useContext(AuthContext);
  const [modalStatus, setModalStatus] = useState(false);

  const toggleModal = () => {
    setModalStatus(!modalStatus);
  }

  if(intersection(["administration"], user.permissions).length === 0)
    return <React.Fragment />;

  return (
    <React.Fragment>
      <Modal
        size="lg"
        centered
        show={modalStatus}
        onHide={toggleModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Debug Object
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{
            height: "800px",
            overflowY: "scroll"
          }}>
            <ReactJson src={props.json} displayDataTypes={false} />
          </div>
        </Modal.Body>
      </Modal>

      <Button variant="light" className="mr-2" onClick={toggleModal}>
        <FaBug style={{marginTop: "-3px"}}/>
      </Button>
    </React.Fragment>
  );
}