import { Form } from "react-bootstrap";
import { useField } from "formik";

/**
 * Renders a generic input on two colums with labels on the left
 * @param {*} props 
 * @returns 
 */
export default function InputSelect(props) {
  const [field] = useField(props);

  return (
    <Form.Control as="select" {...field} {...props}>
      {props.children}
    </Form.Control>
  );
}
