import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { BiTrash } from "react-icons/bi";
import { useHistory } from "react-router";
import useApiDeletor from "utils/apiDeletor";

export default function DeleteObjectButton(props) {
  const [modalStatus, setModalStatus] = useState(false);
  const [modalLock, setModalLock] = useState(false);
  const deletor = useApiDeletor(props.resource);
  const history = useHistory();

  /**
   * Inverts the status of the modal showing or hiding it. It also resets the lock status
   */
  const toggleModal = () => {
    setModalStatus(!modalStatus);

    if(!modalStatus)
      setModalLock(false);
  }

  /**
   * Handles the deletion of the resource
   */
  const handleDeletion = async () => {
    try {
      setModalLock(true);
      await deletor(props.resourceId);

      toggleModal();

      // Run a callback if the prop is set
      if(props.onDeletion)
        props.onDeletion();

      // Redirect to a new location if the prop is set
      if(props.afterDeleteRedirect)
        history.replace(props.afterDeleteRedirect);
    } catch(e) {
      setModalLock(false);
    }
  }

  const modal = (
    <Modal
      show={modalStatus}
      onHide={toggleModal}
      backdrop={modalLock ? "static": true}
    >
      <Modal.Header>
        <Modal.Title className="text-danger">
          Conferma Eliminazione
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <p>Sei sicuro di voler eliminare questo oggetto?</p>
        <p className="mb-0">Non sarà più possibile recuperarlo!</p>

      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" className="mr-2" onClick={handleDeletion} disabled={modalLock}>
          {modalLock && (
            <Spinner
              animation="border"
              size="sm"
              className="mr-2"
            />
          )}
          Elimina
        </Button>

        <Button variant="outline-secondary" onClick={toggleModal} disabled={modalLock}>Annulla</Button>
      </Modal.Footer>
    </Modal>
  );

  /**
   * Returns the appropriate button depending on the requested size
   */
  const button = () => {
    switch(props.buttonType){
      case "inline":
        return (
          <Button variant="danger" className={props.className}  size="sm" onClick={toggleModal}>
            <BiTrash style={{marginTop: "-1px"}}/>
          </Button>
        );

      default:
        return (
          <Button variant="danger" className={props.className} onClick={toggleModal}>
            <BiTrash style={{marginTop: "-3px"}}/> Elimina
          </Button>
        );
    }
  }

  return (
    <React.Fragment>

      {modal}

      {button()}
    </React.Fragment>
  );
}

