import { intersection } from "lodash-es";
import React, { useContext } from "react";
import AuthContext from "../contextes/AuthContext";

/**
 * Conditionally hide the given children based on the user's permissions
 */
export default function PermissionFence(props) {
  const  { user } = useContext(AuthContext);

  const requiredRoles = props.mustHave ? ["administration", ...props.mustHave] : ["administration"];

  if(intersection(requiredRoles, user.permissions).length === 0)
    return <React.Fragment />;
  
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}
