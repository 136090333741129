import { BiShapeCircle } from 'react-icons/bi';



export default function FullPageLoading(props) {
  return (
    <div className="full-page-loading">
      <BiShapeCircle className="loading-spinner"/> Sto caricando i dati
    </div>
  );
}
