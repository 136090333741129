import Table from "rc-table";
import BlockEmpty from "components/BlockEmpty";
import { Link } from "react-router-dom";


const columns = [
  {
    title: "Nome",
    dataIndex:"name",
    render: (v, r) => <Link to={`/administration/workers/${r._id}`}>{v}</Link>
  }
]

export default function  DataTeamWorkersTable(props) {

  return (
    <Table
      columns={columns}
      data={props.data}
      emptyText={<BlockEmpty message="Nessun Operaio" />}
      rowKey={record => record._id}
    />
  );
}
