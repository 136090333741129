import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function InputUpload(props) {
  const formik = useFormikContext();

  const onChange = (event) => {
    formik.setFieldValue(props.name, event.currentTarget.files[0]);
  }
  
  return (
    <div>
      <Form.File
        onChange={onChange}
        accept={props.accept}
        label={props.label || "Seleziona un file..."}
        id={`upload_field_${props.name}`}
        custom
      />

      {formik.errors[props.name] && formik.touched[props.name] ? (
        <div className="text-danger">
          {formik.errors[props.name]}
        </div>
      ) : null}
    </div>
  );
}
