import TableGeneric from "components/data/TableGeneric";
import { Link } from "react-router-dom";


const columns = [
  {
    title: "Chiave",
    dataIndex: 'key',
    width: "50%",
    render: (v, r) => <Link to={`/administration/options/${r._id}/edit`}><code>{v}</code></Link>,
  },
  {
    title: "Valore",
    disableSort: true,
    dataIndex: 'value',
  },
];

/**
 * 
 * 
 * @param props 
 * @returns 
 */
export default function DataOptionsTable(props) {

  return (
    <TableGeneric
      columns={columns}
      resource="core/options"
      rowLinkLocation="core/options"
      defaultSort={["key", "asc"]}
    />
  );
}