import * as Yup from 'yup';
import { formStructure as addressFormStructure, formSchema as addressFormSchema } from '../address';
import { formSchema as sectionCostsMaterialFormSchema } from "./SectionCostsMaterial";
import { formSchema as sectionCostsWorkersFormSchema } from "./SectionCostsWorkers";

// Default form values
const formStructure = {
  name: "",
  typology: "",
  roof_typology: "",
  use_destination: "",
  community_typology: "",
  kilowatts: "",

  team_substructure: "",
  team_panels: "",
  team_inverter: "",
  team_testing: "",

  substructure_forecasted_started_at: "",
  substructure_forecasted_ended_at: "",
  panels_forecasted_started_at: "",
  panels_forecasted_ended_at: "",
  inverter_forecasted_started_at: "",
  inverter_forecasted_ended_at: "",
  testing_forecasted_started_at: "",
  testing_forecasted_ended_at: "",

  address: addressFormStructure,
  annotations: "",
  costs_table_budget: "",
  section_workers: [],
  section_materials: [],
}

// Form validation schema
const formSchema = Yup.object().shape({
  typology: Yup.string()
    .required('Obbligatorio'),
  roof_typology: Yup.string()
    .required('Obbligatorio'),
  use_destination: Yup.string()
    .required('Obbligatorio'),
  community_typology: Yup.string()
    .required('Obbligatorio'),
  kilowatts: Yup.number()
    .min(0, 'Deve essere maggiore o uguale a zero')
    .required('Obbligatorio'),

  substructure_forecasted_started_at: Yup.date(),
  substructure_forecasted_ended_at: Yup.date()
    .min(
      Yup.ref('substructure_forecasted_started_at'),
      "La data di fine non può essere precedente alla data di inizio"
    ),

  panels_forecasted_started_at: Yup.date(),
  panels_forecasted_ended_at: Yup.date()
    .min(
      Yup.ref('substructure_forecasted_started_at'),
      "La data di fine non può essere precedente alla data di inizio"
    ),

  inverter_forecasted_started_at: Yup.date(),
  inverter_forecasted_ended_at: Yup.date()
    .min(
      Yup.ref('substructure_forecasted_started_at'),
      "La data di fine non può essere precedente alla data di inizio"
    ),

  testing_forecasted_started_at: Yup.date(),
  testing_forecasted_ended_at: Yup.date()
    .min(
      Yup.ref('substructure_forecasted_started_at'),
      "La data di fine non può essere precedente alla data di inizio"
    ),

  address: addressFormSchema,
  section_workers: Yup.array().of(sectionCostsWorkersFormSchema),
  section_materials: Yup.array().of(sectionCostsMaterialFormSchema)
});


export { formStructure, formSchema };
