import { NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";

import AuthContext from "../../contextes/AuthContext";

import { authLogout } from "../../utils/auth";

import { useContext } from 'react';
import Identicon from '../Identicon';

/**
 * Renders a dropdown with links to the user's profile
 * 
 * @returns {React.FunctionComponent}
 */
export default function ProfileDropdown() {
  const  {user, setUser}  = useContext(AuthContext);

  /**
   * Handles the logout procedure.
   * Exceptions may be thrown but right now i don't have a nice way of handling them.
   */
  const handleLogout = async () => {
    await authLogout();
    setUser(false);
  }

  return (
    <NavDropdown title={`${user.firstname} ${user.lastname}`} id="basic-nav-dropdown" alignRight>
      <Identicon
        md5={user.email_hash}
        size="200"
        style={{ marginTop: "-10px", marginBottom: "0.5rem", borderTopLeftRadius: "0.25rem", borderTopRightRadius: "0.25rem"}}
        className="img-fluid"
      />
      
      <NavDropdown.Item as={Link} to={`/administration/users/${user._id}`}>Profilo</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
    </NavDropdown>    
  );
};
