import useSWR from 'swr';
import apiFetcher from 'utils/apiFetcher';
import useApiMutator from 'utils/apiMutator';
import { useHistory } from "react-router";

import { formStructure, formSchema } from "forms/administration/user";

import { Container, Alert } from "react-bootstrap";
import FormWrapper from 'components/Forms/FormWrapper';
import FullPageError from 'components/FullPageError';
import BootstrapCard from 'components/BootstrapCard';
import RowInput from 'containers/RowInput';
import PermissionFence from 'components/PermissionFence';
import InputUserPermissions from 'components/Inputs/InputUserPermissions';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export default function UsersEditView(props) {
  const history = useHistory();
  const id = props.match.params.id;

  const { data, error } = useSWR(id ? `users/${id}` : null, apiFetcher);
  const [mutate, mutationError]= useApiMutator("users");

  /**
   * 
   * @param {*} values 
   */
  const handleSubmit = async (values) => {
    const data = await mutate({ user: values });

    if(data)
      history.replace(`/administration/users/${data.results._id}`);
  }

  // If this is a new item, apply the default values
  let initialValues = formStructure;

  if(data?.results) {
    initialValues = data?.results;
  }

  if (error) return <FullPageError type="error" error={error} />
  if (id && !data) return <div>loading...</div>

  return (
    <Container fluid className="content-container pt-3 pb-3">

      <h3 className="mb-3">
        {data ? `Modifica ${data.results.firstname} ${data.results.lastname}` : "Aggiungi Utente" }
      </h3>

      {mutationError && <Alert variant="danger">{mutationError}</Alert>}

      <FormWrapper
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
      >

        <BootstrapCard header="Informazioni di base">

          <RowInput label="Nome" type="text" name="firstname" />
          <RowInput label="Cognome" type="text" name="lastname" />
          <RowInput label="Email" type="email" name="email" />

          <PermissionFence>
            <RowInput label="Permessi" name="permissions">
              <InputUserPermissions name="permissions"/>
            </RowInput>
          </PermissionFence>
          
          <hr />

          <RowInput label="Password" type="password" name="password" />
          <RowInput label="Conferma Password" type="password" name="password_confirmation" />
        </BootstrapCard>

      </FormWrapper>
    </Container>
  );
}
